import { createContext } from 'react';

const AppState = {
    lang:'eng'
}

function AppReducer(state, action){
    switch(action.type){
		case "lang":
			return {lang:action.lang};		
		break;		
		default:
			return {...state};
		break;
	}
}

const AppContext = createContext(AppState);

export { AppContext, AppReducer, AppState };
