import logo from './finalbee-01.png';
import './App.css';
import {useState,useEffect,useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import { BiSkipPreviousCircle } from 'react-icons/bi';
import { BiSkipNextCircle } from 'react-icons/bi';
import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { URL0,blue } from './config';
let linefee = 100;

function App() {
	
	const [data,setData] = useState(null);
	useEffect(()=>{		
		go();	
	},[]);
	
	const go = () => {
		console.log(URL0);
		fetch(URL0 + '/backend.php?action=read', {
			method: 'POST',
			body: '',
			headers: {			
			},
		})
		.then((res) => res.text())
		.then((d) => {
			d = JSON.parse(d);
			console.log(d);
			if (d['result']=='1'){
				setData(d);							
			}
		})
		.catch((err) => console.error(err));	
	}
		
	
	const changestatus = (newstatus,auto_no) => {
		
		let data = {				
			status:newstatus,
			auto_no:auto_no
		};		
		fetch(URL0 + '/backend.php?action=status', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {			
			},
		})
		.then((res) => res.text())
		.then((d) => {
			d = JSON.parse(d);
			if (d['result']=='1'){
				go();
			}
			
		})
		.catch((err) => console.error(err));	
	}

		
	return (<>	
			
			{!!data && <div style={{
				marginTop:"30px",
				paddingTop:"20px",
				
				backgroundColor:"#FFFFFF",
				border:"0px solid black",								
				
				filter: "drop-shadow(1px 1px 5px #808080)",
				}} className="container-lg">
								
				<b>Backend </b> <br /><br /><br /><br />
				
				<b>IP invoice : </b> <br />			
				
				<table><tr >
							<td> Inv # </td>
							<td> Member #</td>
							<td> Qty of /24 </td>
							<td> Period(Month)</td>
							<td> Input time</td>
							</tr>
				
				{data.ipline.map(x=>{					
					return <tr  key={'ipline'+x['auto_no']} >
							<td> {x['auto_no']}</td>
							<td> {x['member_no']}</td>
							<td> {x['ipqty']}</td>
							<td> {x['period1']}</td>
							<td> {x['input_time']}</td>							
						</tr>})}
				</table>
						

				<br />			<br />							
				<b>Cloud Machine Invoices: </b> <br />			
				
				<table style={{width:"100%"}} border="1">
					<tr>
						<td> Inv # </td>
						<td> Member #</td>
						<td> OS</td>
						<td> CPU</td>
						<td> RAM</td>
						<td> HD</td>
						
						<td> Local<br /> Bandwidth</td>
						<td> China DIrect <br />Bandwidth</td>
						<td> Int'l <br /> Bandwidth</td>
						<td> Amount</td>
						
						<td> Input time</td>
					</tr>
				
				{data.machine.map(x=>{					
					return <tr key={'mach'+x['auto_no']} >
							<td> {x['auto_no']}</td>
							<td> {x['member_no']}</td>
							<td> {x['os']}</td>
							<td> {x['cpu']}</td>
							<td> {x['ram']}</td>
							<td> {x['hd']}</td>
							<td> {x['local0']}</td>
							<td> {x['china']}</td>
							<td> {x['intl']}</td>
							<td> {x['amount']}</td>
							<td> {x['input_time']}</td>
							</tr>
				})}
				</table>
				
				<br />			<br />			
				<b>Members : </b> <br />			
				
				<table style={{width:"100%"}} border="1">
					<tr>
						<td> Member # </td>
						<td> Name</td>						
						<td> Email</td>
						<td> Bill Email</td>
						<td> Phone</td>						
						
						<td> AS#</td>
						<td> Company</td>
						<td> Addr</td>
						<td> Website</td>						
						<td> BR Copy</td>						
						<td> Input time</td>
						<td> Status</td>
					</tr>
				
				{data.members.map(x=>{					
					return <tr  key={'member'+x['auto_no']} >
							<td> {x['auto_no']}</td>
							<td> {x['name1']}</td>
							<td> {x['email']}</td>
							<td> {x['email2']}</td>
							<td> {x['phone']}</td>
							<td> {x['asnumber']}</td>
							<td> {x['company']}</td>
							<td> {x['addr']}</td>
							<td> {x['website']}</td>
							<td> <a target="_blank" href={URL0 + '/backend.php?action=br&fn=' + x['fn']}> BR Download</a> </td>
							<td> {x['input_time']}</td>
							<td> {x['auto_no']>1?<span style={{color:blue,cursor:'pointer'}} onClick={()=> changestatus(x['status']=='inactive'?'active':'inactive',x['auto_no'])}  > 
							{x['status']} </span>:'active'} </td>
							</tr>
				})}
				</table>
				
				
				
				<br />
				
				
				<br />
				<br />
				<br />				
			</div>}
			<hr />
			<br />
		</>
			
	);
}

export default App;
