//import logo from './finalbee-01.png';
import './App.css';
import {useState,useEffect,useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaGreaterThan,FaLessThan } from "react-icons/fa";
import {BsFillHouseDoorFill,BsChevronBarExpand } from "react-icons/bs";
import BackendBanner from './BackendBanner';

import Welcome from './Welcome';
import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import ReactSlider from "react-slider";
import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { onelinefree,URL0,blue,linefee } from './config';
import Info from './Info';

var tabsContent = {margin:"0px",border:"1px solid #E0E0E0",borderTop:"0px solid black"};

let infomap = {				
				"agree":"Please tick the agreement of the T&C",
				"network":"Total bandwidth should be between 1MB to 1000MB",								
			};
			
function App() {
		
	const ipqtyRef = useRef();
	const periodRef = useRef();
	const agreeRef = useRef();
	
	const [CPU,setCPU] = useState(4);
	const [RAM,setRAM] = useState(4);
	const [HD,setHD] = useState(200);
	const [local,setLocal] = useState(10);
	const [china,setChina] = useState(0);
	const [intl,setIntl] = useState(0);
	const [amount,setAmount] = useState(0);
	const [os,setOS] = useState('centos');
 		
	const [show1, setShow1] = useState(false);
	const [infos,setInfos] = useState([]);
	
	useEffect(()=>{		
		//setIpqty(ipqtyRef.current.value);
		//setPeriodLabel(periodRef.current.options[periodRef.current.selectedIndex].text);
		//setPeriodValue(periodRef.current.value);				
	},[]);
	
	useEffect( ()=>{		
		if (totalBandwidth()>1000){
			setInfos(['network']);
		} else if (totalBandwidth()==0) {
			setInfos(['network']);
		} else {
			setInfos([]);
		}
	},[china,local,intl]);
	
	
	useEffect( ()=>{		
		setAmount( onelinefree + china*200 + (intl*20) + (local*1) + HD*1 + (RAM*100) + (CPU*100) );
	},[china,local,intl,HD,RAM,CPU]);
			
	const checkValid = () => {
		let tmp = [];	
		if (!agreeRef.current.checked){
			tmp.push('agree');
		}
		
		if (local + intl + china > 1000){
			tmp.push('network');
		}		
		setInfos(tmp);			
		if (tmp.length==0){			
			submit();
		}				
	}
	
	const totalBandwidth = () => {
		return china + local + intl;
	}
	
	const btnstyle = (choose) => {
			return {margin:"4px 4px 4px 4px",
					padding:"10px 10px 10px 10px",
					border:"1px solid black",
					cursor:'pointer',
					backgroundColor:choose==os?blue:'white',
					color:choose==os?'white':'black'
			}
	};

	const showInfo = (x) => {
		return infos.includes(x) && <Info infomap={infomap} x={x} />		
	}

	const submit = (fn) => {
		let data = {
				cpu:CPU,
				ram:RAM,
				hd:HD,
				os:os,
				local:local,
				china:china,
				intl:intl,
				amount:amount
		};
		
		fetch(URL0 + '/post.php?action=buymachine', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {			
			},
		})
		.then((res) => res.text())
		.then((data) => {
			console.log(data);
			data = JSON.parse(data);
			if (data['result']){				
				setShow1(true);
			}			
		})
		.catch((err) => console.error(err));
	}
	

	return (<>		
			<Modal show={show1} onHide={()=>setShow1(false)}>
				<Modal.Header closeButton>
				<Modal.Title>Thank You</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				Order success. We will proceed to you later.
				</Modal.Body>
				 <Modal.Footer>
				<Button variant="secondary" onClick={()=>setShow1(false)}>
				Close
				</Button>
				
				</Modal.Footer>
			</Modal>
	
			<div style={{
				marginTop:"10px",
				paddingTop:"20px",
				backgroundColor:"#FFFFFF",
				border:"0px solid black",		
				filter: "drop-shadow(1px 1px 5px #808080)",
				}} className="container-lg">
				
				<div style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
					{/* <div><input type='button' style={{border:"1px solid " + blue,color:blue,fontWeight:'bold'}} value=' Login ' /> </div>*/}				
				</div>
				
				<BackendBanner />		
				
				<hr />
				
				<Welcome />
				
				<div style={{fontWeight:'bold',fontSize:"20px",marginBottom:"20px"}}>Cloud Services</div>
				
				<div className="mm1" style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"space-between"}}>
				
					<div className="mm2" >
					
					
						<div className='f-s-b' style={{border:"0px solid black"}}>
							<div style={{display:"flex",flexDirection:"column",justifyContent:'center',width:"5%",backgroundColor:blue}}>
								<div style={{color:'white',transform:"rotate(-90deg)"}}>OS </div>
							</div>
							
							<div style={{width:"95%",border:"0px solid red",paddingLeft:"0%"}} >
								
								<div style={{border:"0px solid green"}}>																		
									<div className='field1' >									
									
										<div className='columns' style={{...tabsContent,borderBottom:"0px solid black"}} >									
											
											<div className='column'>
												<b>OS</b>
												<div style={{display:'flex',flexDirection:'row'}} className='border1' >									
													<div onClick={()=>setOS('centos')} style={btnstyle('centos')}>
														CentOS
													</div>
													<div onClick={()=>setOS('ubuntu')} style={btnstyle('ubuntu')}>
														Ubuntu
													</div>
													<div onClick={()=>setOS('debian')} style={btnstyle('debian')}>
														Debian
													</div>
													<div onClick={()=>setOS('freebsd')} style={btnstyle('freebsd')}>
														FreeBSD
													</div>
													<div onClick={()=>setOS('window')} style={btnstyle('window')}>
														Windows
													</div>
												</div>
											</div>										
										</div>	
										{os=='window'?<div style={{color:blue}}>
										Windows Trial Version. Customers are responsible for buying license
										</div>:<div>&nbsp;</div>}
									</div>						
									
								</div>
								
								
								
							</div>
						</div>
					
						<br />
						
						<div className='f-s-b' style={{border:"0px solid black"}}>
							<div style={{display:"flex",flexDirection:"column",justifyContent:'center',width:"5%",backgroundColor:blue}}>
								<div style={{color:'white',transform:"rotate(-90deg)"}}>SPEC </div>
							</div>
							
							<div style={{width:"95%",border:"0px solid red",paddingLeft:"0%"}} >
								
								<div style={{border:"0px solid green"}}>																		
									<div className='field1' >									
									
										<div className='columns' style={{...tabsContent,borderBottom:"0px solid black"}} >									
											
											<div className='column'>
												<b>CPU</b>
												<div className='f-s-b border1' >									
													<input className="alignright text1" type="text" 
														onChange={(e)=> setCPU(e.target.value) } 
														onBlur={ (e) => setCPU(e.target.value<4?4:(e.target.value>16?16:e.target.value) ) } 
														placeholder="CPU" 
														value={CPU}  />
													<div className='label1x' > vCPU </div>		
												</div>
											</div>										
										</div>	
										
										
										<ReactSlider
											className="horizontal-slider"
											marks
											markClassName="example-mark"
											min={0}
											max={16}										
											value={[ CPU ]}
											thumbClassName="example-thumb"
											trackClassName="example-track"											
											onChange={(v,i)=>{																								
												setCPU(v<4?4:v);												
											}}
											renderThumb={(props, state) =>{																											
												return <div {...props} 														
														><div className='slider-border1'>&nbsp;</div>
													</div>;
											}}
										/>	
										
									</div>						
								</div>
								
								
								
								<div style={{border:"0px solid green"}}>																		
									<div className='field1' >									
									
										<div className='columns' style={{...tabsContent,borderBottom:"0px solid black"}} >									
											
											<div className='column'>
												<b>RAM</b>
												<div className='f-s-b border1' >									
													<input className="alignright text1" type="text" 
														onChange={(e)=> setRAM(e.target.value) } 
														onBlur={ (e) => setRAM(e.target.value<4?4:(e.target.value>32?32:e.target.value) ) } 														
														placeholder="RAM" 
														value={RAM}  />
													<div className='label1x' > GB </div>		
												</div>
											</div>										
										</div>	
										
										<ReactSlider
											className="horizontal-slider"
											marks
											markClassName="example-mark"
											min={0}
											max={32}										
											value={[ RAM ]}
											thumbClassName="example-thumb"
											trackClassName="example-track"											
											onChange={(v,i)=>{												
												setRAM(v<4?4:v);												
											}}
											renderThumb={(props, state) =>{																											
												return <div {...props} 														
														><div className='slider-border1'>&nbsp;</div>
													</div>;
											}}
										/>	
									</div>	
								</div>	

								
								
								
							</div>
						</div>
						
						
						<br />
						
						<div className='f-s-b' style={{border:"0px solid black"}}>
							<div style={{display:"flex",flexDirection:"column",justifyContent:'center',width:"5%",backgroundColor:blue}}>
								<div style={{color:'white',marginTop:"40px",transform:"rotate(-90deg)"}}>STORAGE</div>
							</div>
							
							<div style={{width:"95%",border:"0px solid red",paddingLeft:"0%"}} >
							
								<div style={{border:"0px solid green"}}>																		
									<div className='field1' >									
									
										<div className='columns' style={{...tabsContent,borderBottom:"0px solid black"}} >									
											
											<div className='column'>
												<b>HD Storage</b>
												<div className='f-s-b border1' >									
													<input className="alignright text1" type="text" 
														onChange={(e)=> setHD(e.target.value) } 
														onBlur={ (e) => setHD(e.target.value<200?200:(e.target.value>1000?1000:e.target.value) ) } 														
														placeholder="HD" 
														value={HD}  />
													<div className='label1x' > GB </div>		
												</div>
											</div>										
										</div>	
										
										<ReactSlider
											className="horizontal-slider"
											marks
											markClassName="example-mark"
											min={0}
											step={50}
											max={1000}										
											value={[ HD ]}
											thumbClassName="example-thumb"
											trackClassName="example-track"											
											onChange={(v,i)=>{																								
												setHD(v<200?200:v);												
											}}
											renderThumb={(props, state) =>{																											
												return <div {...props} 														
														><div className='slider-border1'>&nbsp;</div>
													</div>;
											}}
										/>	
									</div>	
								</div>	
						
							</div>
						</div>
						
						
						<br />
						
						
						<div className='f-s-b' style={{border:"0px solid black"}}>
							<div style={{display:"flex",flexDirection:"column",justifyContent:'center',width:"5%",backgroundColor:blue}}>
								<div style={{color:'white',transform:"rotate(-90deg)"}}>NETWORK</div>
							</div>
							
							<div style={{width:"95%",border:"0px solid red",paddingLeft:"0%"}} >
							
								<div style={{border:"0px solid green"}}>																		
									<div className='field1' >									
									
										<div className='columns' style={{...tabsContent,borderBottom:"0px solid black"}} >									
											
											<div className='column'>
												<b>Local Bandwidth</b>
												<div className='f-s-b border1' >									
													<input className="alignright text1" type="text" 
														onChange={(e)=> setLocal(e.target.value) } 
														onBlur={ (e) => setLocal(e.target.value<0?0:(e.target.value>1000?1000:e.target.value) ) } 														
														placeholder="Local Bandwidth" 
														value={local}  />
													<div className='label1x' > MB </div>		
												</div>
											</div>										
										</div>	
										
										<ReactSlider
											className="horizontal-slider"
											marks
											markClassName="example-mark"
											min={0}
											step={10}
											max={1000}										
											value={[ local ]}
											thumbClassName="example-thumb"
											trackClassName="example-track"											
											onChange={(v,i)=>{																								
												setLocal(v);												
											}}
											renderThumb={(props, state) =>{																											
												return <div {...props} 														
														><div className='slider-border1'>&nbsp;</div>
													</div>;
											}}
										/>	
									</div>	
								</div>	
								
								
								<div style={{border:"0px solid green"}}>																		
									<div className='field1' >								
									
										<div className='columns' style={{...tabsContent,borderBottom:"0px solid black"}} >									
											
											<div className='column'>
												<b>Int'l Bandwidth</b>
												<div className='f-s-b border1' >									
													<input className="alignright text1" type="text" 
														onChange={(e)=> setIntl(e.target.value) } 
														onBlur={ (e) => setIntl(e.target.value<0?0:(e.target.value>1000?1000:e.target.value) ) } 
														placeholder="Int'; Bandwidth" 
														value={intl}  />
													<div className='label1x' > MB </div>		
												</div>
											</div>										
										</div>	
										
										<ReactSlider
											className="horizontal-slider"
											marks
											markClassName="example-mark"
											min={0}
											step={10}
											max={1000}										
											value={[ intl ]}
											thumbClassName="example-thumb"
											trackClassName="example-track"											
											onChange={(v,i)=>{																								
												setIntl(v);												
											}}
											renderThumb={(props, state) =>{																											
												return <div {...props} 														
														><div className='slider-border1'>&nbsp;</div>
													</div>;
											}}
										/>	
									</div>	
								</div>
								
								
								
								<div style={{border:"0px solid green"}}>																		
									<div className='field1' >								
									
										<div className='columns' style={{...tabsContent,borderBottom:"0px solid black"}} >									
											
											<div className='column'>
												<b>China Direct Bandwidth</b>
												<div className='f-s-b border1' >									
													<input className="alignright text1" type="text" 
														onChange={(e)=> setChina(e.target.value) } 
														onBlur={ (e) => setChina(e.target.value<0?0:(e.target.value>100?100:e.target.value) ) } 
														placeholder="China Direct Bandwidth" 
														value={china}  />
													<div className='label1x' > MB </div>		
												</div>
											</div>										
										</div>	
										
										<ReactSlider
											className="horizontal-slider"
											marks
											markClassName="example-mark"
											min={0}
											step={1}
											max={100}										
											value={[ china ]}
											thumbClassName="example-thumb"
											trackClassName="example-track"											
											onChange={(v,i)=>{																								
												setChina(v);												
											}}
											renderThumb={(props, state) =>{																											
												return <div {...props} 														
														><div className='slider-border1'>&nbsp;</div>
													</div>;
											}}
										/>	
									</div>	
								</div>								
						
							</div>
						</div>
					</div>
					
					
					<div className="mm3"   >
						<div>						
							<div>
								<ul className="list-group">
									<li className="list-group-item disabled" aria-disabled="true"><b>Total Cost </b></li>
									<li className="list-group-item"> <div className='f-s-b'> 
																	<div> vCPU : {CPU} cores  &nbsp;  </div> 
																	<div> HK$ {(CPU*100)} </div> 
																	</div>
																</li>
									
									<li className="list-group-item"> <div className='f-s-b'> 
																	<div> RAM : {RAM} GB  &nbsp; </div> 
																	<div> HK$ {(RAM*100)} </div> 
																	</div>
																</li>
																
									<li className="list-group-item"> <div className='f-s-b'> 
																	<div> Storage : {HD} GB  &nbsp; </div> 
																	<div> HK$ {(HD*1)} </div> 
																	</div>
																</li>
																
																
									<li className="list-group-item"> <div className='f-s-b'> 
																	<div> Local Bandwidth : {local} MB  &nbsp; </div> 
																	<div> HK$ {(local*1)} </div> 
																	</div>
																</li>
																
																
									<li className="list-group-item"> <div className='f-s-b'> 
																	<div> Int'l Bandwidth : {intl} MB  &nbsp; </div> 
																	<div> HK$ {(intl*20)} </div> 
																	</div>
																</li>							
																
									<li className="list-group-item"> <div className='f-s-b'> 
																	<div> China Direct Bandwidth : {china} MB  &nbsp; </div> 
																	<div> HK$ {(china*200)} </div> 
																	</div>
																</li>
																
									<li className="list-group-item"> <div className='f-s-b'> 
																	<div> One IP </div> 
																	<div> HK$ {onelinefree} </div> 
																	</div>
																</li>							
															 
																
									<li className="list-group-item">&nbsp;</li>
									
									<li className="list-group-item"> <div className='f-s-b'> 
																	<div> <b>Total  </b></div> 
																	<div> <b> HK$ {amount} </b> </div> 
																	</div>
																</li>
								</ul>
																
							</div>

							<br /><br />
							
							{showInfo('agree')}
								
							<div >
								<span style={{fontSize:'15px'}}>
								<input ref={agreeRef} type='checkbox' style={{marginRight:"4px"}} />
								<span className='noselect' style={{cursor:"pointer"}} onClick={()=> agreeRef.current.checked=!agreeRef.current.checked} > I have read and agreed to the <a target="_blank" 
										href="https://wpsdhk.com/tandc.pdf" 
										style={{color:'blue'}} > General Terms and Conditions </a>
								</span>
								</span>
							</div>
								
							
							<div style={{margin:"0px",marginTop:"20px",marginBottom:"20px"}}>
								<input style={{border:"2px solid "+blue,backgroundColor:(totalBandwidth()>1000||totalBandwidth()==0)?'gray':blue,color:'white',width:"100%"}} disabled={totalBandwidth()>1000} type='button' value='Submit' onClick={()=>checkValid()} />
										
							</div>	
							{showInfo('network')}							
						
							<br /><br />							
							
						</div>
					</div>
				</div>		
				<br />				
			</div>
			<hr />
			<br />
			

			
		</>
			
	);
}

export default App;
