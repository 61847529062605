//import logo from './finalbee-01.png';
import {AiFillInfoCircle} from 'react-icons/ai';

import { URL,blue } from './config';

function App({x,infomap}) {
	
	return (<>		
			<div style={{display:"flex",alignItems:"center",flexDirection:"row"}}>
				<AiFillInfoCircle color={blue} size={25} />
					<span style={{fontSize:"15px",color:"#808080"}}>{infomap[x]}</span>
				</div>
		</>
			
	);
}

export default App;
