//import logo from './finalbee-01.png';
import './App.css';
import {useState,useEffect,useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Welcome from './Welcome';
import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import BackendBanner from './BackendBanner';

import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { URL0,blue,linefee } from './config';
import Info from './Info';

let infomap = {				
				"agree":"Please tick the agreement of the T&C",
				'asnumber':"Please provide ASNumber"
			};
			
function App() {
		
	const ipqtyRef = useRef();
	const periodRef = useRef();
	const agreeRef = useRef();
	const [periodLabel,setPeriodLabel] = useState('-');
	const [periodValue,setPeriodValue] = useState(0);
	const [ipqty,setIpqty] = useState(0);
	const [show1, setShow1] = useState(false);
	const [infos,setInfos] = useState([]);
	const [companyData,setCompanyData] = useState({});
	const [asnumberShown,setAsnumberShown] = useState(true);
	useEffect(()=>{		
		setIpqty(ipqtyRef.current.value);
		setPeriodLabel(periodRef.current.options[periodRef.current.selectedIndex].text);
		setPeriodValue(periodRef.current.value);				
	},[]);
		
	const checkValid = () => {
		let tmp = [];	
		if (!agreeRef.current.checked){
			tmp.push('agree');
		}	
		if (asnumberShown && companyData.asnumber==''){
			tmp.push('asnumber');
		}	
		setInfos(tmp);			
		if (tmp.length==0){			
			submit();
		}				
	}
	
	const total = () => {
		return ipqty * periodValue * linefee + (asnumberShown==''?800:0) ;
	}
	
	const unitPrice = () => {
		return linefee;
	}
	
	const showInfo = (x) => {
		return infos.includes(x) && <Info infomap={infomap} x={x} />		
	}
	
	const optionamount = () => {
		let num = [];
		for (let i=1;i<=256;i++){
			num.push(i);
		}		
		return <>{num.map(i=><option key={'a'+i} value={i}> {i} / 24 ( {256*i} )</option>)}</>		
	}	
	
	

	const submit = (fn) => {
		let data = {
				ipqty:ipqtyRef.current.value,
				period1:periodRef.current.value,
				name:companyData.name,
				phone:companyData.phone,
				asnumber:companyData.asnumber,
				company:companyData.company,
				addr:companyData.addr,
				email:companyData.email
		};
		
		fetch(URL0 + '/post.php?action=buyip', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {			
			},
		})
		.then((res) => res.text())
		.then((data) => {
			data = JSON.parse(data);
			if (data['result']){				
				setShow1(true);
			}			
		})
		.catch((err) => console.error(err));
	}
	
	const updatedata = (x) => {
		//console.log(x);
		setCompanyData(x);		
		if (x['asnumber']==''){
			setAsnumberShown(false);
		}
	}	
	
	const modiCompanyData = (newvalue) => {		
		setCompanyData({...companyData,...newvalue});
	}

	const termoption = () => {
		let terms = [
					/*{label:"1 month",month:1},
					{label:"3 months",month:3},
					{label:"6 months",month:6},*/
					{label:"1 year",month:12},
					{label:"2 years",month:24},
					{label:"3 years",month:36},
					/*{label:"4 years",month:48},
					{label:"5 years",month:60},
					{label:"6 years",month:72},
					{label:"7 years",month:84},
					{label:"8 years",month:96}*/
				];
		return <>{terms.map(x=> <option key={'b'+x['month']} value={x['month']}> {x['label']} </option>  )}</>
		
	}	
		
	return (companyData?<>	
	
			<Modal show={show1} onHide={()=>setShow1(false)}>
				<Modal.Header closeButton>
				<Modal.Title>Thank You</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				Order success. We will proceed to you later.
				</Modal.Body>
				 <Modal.Footer>
				<Button variant="secondary" onClick={()=>setShow1(false)}>
				Close
				</Button>
				
				</Modal.Footer>
			</Modal>
			
			<div style={{
				marginTop:"10px",
				paddingTop:"20px",
				backgroundColor:"#FFFFFF",
				border:"0px solid black",		
				filter: "drop-shadow(1px 1px 5px #808080)",
				}} className="container-lg">
				
				<div style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
					{/* <div><input type='button' style={{border:"1px solid " + blue,color:blue,fontWeight:'bold'}} value=' Login ' /> </div>*/}				
				</div>
				
				<BackendBanner />
				
				<hr />
				
				<Welcome updatedata={updatedata} />
				
				<div style={{fontWeight:'bold',fontSize:"20px",marginBottom:"20px"}}>IP Services</div>
				
				<div className="mm1" style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"space-between"}}>
				
					<div className="mm2"  >
					
						<div style={{marginTop:"0px",marginBottom:"20px",width:"100%",border:"0px solid black",display:"flex",flexDirection:"row"}}>
							<div style={{width:"50%"}}>
								Amount of /24 <br />
								<select ref={ipqtyRef} onChange={(event)=>{setIpqty(event.target.value)} }  className="form-select" style={{marginTop:"20px",width:"90%"}}>
									{optionamount()}
								</select>							
							</div>							
							<div style={{width:"50%"}}>
								Service Term <br />
								<select ref={periodRef} onChange={(event)=> { setPeriodValue(event.target.value);setPeriodLabel(event.target.options[event.target.selectedIndex].text );  } }    className="form-select" style={{marginTop:"20px",width:"100%"}}>{termoption()}</select>
							</div>
						</div>
						
						<hr style={{marginTop:"40px",marginBottom:"40px"}} />
																
							Contact Person<br />
							<input  style={{marginTop:"10px",width:"100%"}} type='text' onChange={(e)=>modiCompanyData({name:e.target.value})} value={companyData['name']} />
							<br />
							
							Company Name<br />
							<input style={{marginBottom:"10px",width:"100%"}} type='text' onChange={(e)=>modiCompanyData({company:e.target.value})} value={companyData['company']} />
							<br />
							
							Email<br />
							<input style={{marginBottom:"10px",width:"100%"}} type='text' onChange={(e)=>modiCompanyData({email:e.target.value})} value={companyData['email']} />
							<br />
					
							Phone<br />
							<input style={{marginBottom:"10px",width:"100%"}} type='text' onChange={(e)=>modiCompanyData({phone:e.target.value})} value={companyData['phone']} />
							<br />
						
							Addr<br />
							<input style={{marginBottom:"10px",width:"100%"}} type='text' onChange={(e)=>modiCompanyData({addr:e.target.value})} value={companyData['addr']} />
							<br />
						
							<input type='checkbox' onClick={()=>{setAsnumberShown(!asnumberShown); modiCompanyData({asnumber:''}) } }   checked={asnumberShown} /> I have ASNumber
							<br />
							{asnumberShown?<>ASNumber<br />
							<input style={{marginBottom:"10px",width:"100%"}} type='text' onChange={(e)=>modiCompanyData({asnumber:e.target.value})} value={companyData['asnumber']} />
							<br /></>:<div> <b>We will provide ASNumber for you. HK$800 will be charged.</b></div>}
					</div>
					
					<div className="mm3"  >
						<div>
							<ul className="list-group">
								<li className="list-group-item disabled" aria-disabled="true"><b>Total Cost </b></li>
								<li className="list-group-item"> <div className='f-s-b'> 
																<div> {ipqty} &nbsp; x &nbsp; /24 &nbsp; x &nbsp; {periodLabel} </div> 
																<div> HK$ {total()} </div> 
																</div>
															</li>
								<li className="list-group-item"> <div className='f-s-b'> 
																<div> IP monthly price </div> 
																<div> HK$ {unitPrice()} /IP </div> 
																</div>
															</li>
								
								{!asnumberShown?<li className="list-group-item"> <div className='f-s-b'> 
																<div> ASNumber </div> 
																<div> HK$ 800 </div> 
																</div>
												</li>:null}							
								
								
								<li className="list-group-item">&nbsp;</li>
								
								<li className="list-group-item"> <div className='f-s-b'> 
																<div> <b>Total  </b></div> 
																<div> <b>HK$ {total()} </b></div> 
																</div>
															</li>
							</ul>
						</div>
						
						<br /><br />
						{showInfo('agree')}
						{showInfo('asnumber')}
						<div >
							<span style={{fontSize:'15px'}}>
							<input  ref={agreeRef} type='checkbox' style={{marginRight:"4px"}} />
							<span className='noselect' style={{cursor:"pointer"}} onClick={()=> agreeRef.current.checked = !agreeRef.current.checked }  > I have read and agreed to the <a target="_blank" href="https://wpsdhk.com/tandc.pdf" style={{color:'blue'}} > General Terms and Conditions </a>
							</span>
							</span>
						</div>
						
						<div style={{margin:"0px",border:"0px solid black",marginTop:"20px",marginBottom:"20px"}}>
							<input style={{border:"2px solid "+blue,backgroundColor:blue,color:'white',width:"100%"}} 
								type='button' value='Submit' onClick={()=>checkValid()} />
						</div>
						
					</div>
				</div>		
				<br />				
			</div>
			
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
		</>:null
			
	);
}

export default App;
