//import logo from './finalbee-01.png';
import './App.css';
import {useState,useEffect,useRef,useReducer} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import { AiFillFacebook , AiFillInstagram } from 'react-icons/ai';
import { AppContext,AppState,AppReducer } from './context';

import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { blue,URL } from './config';
import Header from './Header';
import Button1 from './Button1';
import { useNavigate } from "react-router-dom";
import Footer from './Footer';
import pic1 from './img/solution.jpeg';
import solution from './img/solution.jpeg';
import linepic from './img/line.jpeg';
import ebiz from './img/ebiz2.jpeg';
import macpic from './img/mac.jpeg';
import designpic from './img/design.jpeg';

import logo from './img/logo.png';

import iconhtm from './img/icon_htm.png';
import iconcloud from './img/icon_cloud.png';
import icondev from './img/icon_dev.png';
import iconsys from './img/icon_sys.png';

import webc from './img/webc.jpeg';
import {lang} from './lang';

function App() {	
	
	const emailRef = useRef();	
	const passwordRef = useRef();
	const [msg,setMsg] = useState('');
	const [show1,setShow1] = useState(false);
	const navigate = useNavigate();
	const [redirect,setRedirect] = useState(false);
	const [state,dispatch] = useReducer(AppReducer,AppState);
	
	useEffect(()=>{		
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);		
		if (!!urlParams.get('lang')){
			dispatch({type:'lang',lang:urlParams.get('lang')});
		}
	},[]);	
		
	return (<>	
			<AppContext.Provider value={{state, dispatch}}>
			<Modal show={show1} onHide={()=>  { redirect?navigate("/machine", { replace: true }):setShow1(false) }    }>
				<Modal.Header closeButton>
				<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				{msg}
				</Modal.Body>
				 <Modal.Footer>
				<Button variant="secondary" onClick={()=>  { redirect?navigate("/machine", { replace: true }):setShow1(false) }  }>
				Close
				</Button>
				
				</Modal.Footer>
			</Modal>			
			
			<Header txt={lang['m_service0'][state.lang]} showDetail={false} pic1={pic1} vh={'50vh'} />
						
			{/* end first element */ }			
			
			
			<div className='desktop-div'>
				<br />
				
				<div style={{width:"100%",
					height:"80vh",
					backgroundImage:`url(${ebiz})`,
					backgroundRepeat:'no-repeat',					
					backgroundSize:'cover',
					display:"flex",
					flexDirection:"row",
					justifyContent:"space-between",
					}}>		

					<div style={{width:"60%"}}>
					</div>
					
					<div style={{display:'flex',
						flexDirection:'column',
						width:"30%",
						justifyContent:'center',
						alignItems:'center',
						backgroundColor:'rgba(0,0,0,0.5)'}}>
						
						<div style={{width:"80%",border:"0px solid red"}}>								
							<div style={{marginBottom:"10px",fontWeight:'bold',fontSize:'40px',color:"white"}}> 
								{lang['m_service_1_title'][state.lang]} </div>
							
							<div style={{marginBottom:"20px",color:'white'}}> 
								{lang['m_service_1_content'][state.lang]}
							</div>
						</div>
							
					</div>
					
					<div style={{widht:"10%"}}>
					</div>
						
				</div>
			</div>	
			
			<div className='mobile-div'>
				<div style={{width:"100%",
					height:"40vh",
					backgroundImage:`url(${ebiz})`,
					backgroundRepeat:'no-repeat',					
					backgroundSize:'cover',
					display:"flex",
					flexDirection:"row",
					justifyContent:"space-between",
					}}>		
				</div>
				
				<div className='mobile-div8'>
					<div> 
					{lang['m_service_1_title'][state.lang]} </div>					
					<div > 
						{lang['m_service_1_content'][state.lang]}
					</div>	
				</div>
				
			</div>
			
			<br />			
					
			<div className='desktop-div'>
			
				<div style={{width:"100%",
					height:"80vh",
					backgroundImage:`url(${webc})`,
					backgroundRepeat:'no-repeat',					
					backgroundSize:'cover',
					display:"flex",
					flexDirection:"row",
					justifyContent:"space-between",
					}}>		

					<div style={{widht:"20%"}}>
					</div>
					
					<div style={{display:'flex',
								flexDirection:'column',
								width:"30%",
								justifyContent:'center',
								alignItems:'center',
								backgroundColor:'rgba(0,0,0,0.5)'}}>
								
								<div style={{width:"80%",border:"0px solid red"}}>								
									<div style={{marginBottom:"10px",fontWeight:'bold',fontSize:'40px',color:"white"}}> 
									{lang['m_service_2_title'][state.lang]} <br /> </div>
									
									<div style={{marginBottom:"20px",color:'white'}}> 
										{lang['m_service_2_content'][state.lang]}
									</div>	
									
								</div>
							
					</div>
					
					
					<div style={{width:"50%"}}>
					</div>
						
				</div>
			</div>	

			<div className='mobile-div'>
				<div style={{width:"100%",
					height:"40vh",
					backgroundImage:`url(${solution})`,
					backgroundRepeat:'no-repeat',					
					backgroundSize:'cover',
					display:"flex",
					flexDirection:"row",
					justifyContent:"space-between",
					}}>		
				</div>
				
				<div className='mobile-div8'>
					<div> 
					{lang['m_service_2_title'][state.lang]} </div>					
					<div > 
						{lang['m_service_2_content'][state.lang]}
					</div>	
				</div>				
			</div>			
			
			<br />
			
			
			<div className='desktop-div'>
				<div style={{width:"100%",
					height:"80vh",
					backgroundImage:`url(${designpic})`,
					backgroundRepeat:'no-repeat',					
					backgroundSize:'cover',
					display:"flex",
					flexDirection:"row",
					justifyContent:"space-between",
					}}>		

					<div style={{width:"60%"}}>
					</div>
					
					<div style={{display:'flex',
							flexDirection:'column',
							width:"30%",
							justifyContent:'center',
							alignItems:'center',
							backgroundColor:'rgba(0,0,0,0.5)'}}>
							
							<div style={{width:"80%",border:"0px solid red"}}>								
								<div style={{marginBottom:"10px",fontWeight:'bold',fontSize:'40px',color:"white"}}> 
								{lang['m_service_3_title'][state.lang]} </div>
								
								<div style={{marginBottom:"20px",color:'white'}}> 
									{lang['m_service_3_content'][state.lang]}
								</div>
							</div>
							
					</div>
					<div style={{widht:"10%"}}>
					</div>
						
				</div>
			</div>	
			
			<div className='mobile-div'>
				<div style={{width:"100%",
					height:"40vh",
					backgroundImage:`url(${solution})`,
					backgroundRepeat:'no-repeat',					
					backgroundSize:'cover',
					display:"flex",
					flexDirection:"row",
					justifyContent:"space-between",
					}}>		
				</div>
				
				<div className='mobile-div8'>
					<div> 
					{lang['m_service_3_title'][state.lang]} </div>					
					<div > 
						{lang['m_service_3_content'][state.lang]}
					</div>	
				</div>
				
			</div>
			
			
			<Footer />			
						
			
			</AppContext.Provider>
		</>
			
	);
}

export default App;
