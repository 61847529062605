//import logo from './finalbee-01.png';
import './App.css';
import {useState,useEffect,useRef,useContext} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppContext} from './context';
import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import { AiFillFacebook , AiFillInstagram } from 'react-icons/ai';

import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { blue,URL } from './config';
import { useNavigate } from "react-router-dom";
import Button1 from './Button1';
import logo from './img/logo.png';
import {lang} from './lang';

function App({pic1,vh}) {	
	
	const {state} = useContext(AppContext);
	
	useEffect(()=>{		
				
	},[]);	
		
	return (<>	
			<div className='desktop-div'>
				<div style={{width:"100%",
					height:"50vh",				
					display:"flex",
					border:"0px solid black",
					flexDirection:"column",				
					backgroundColor:'#E0E0E0',
					justifyContent:"center",
					alignItems:'center',
					}}>	
					<div style={{paddingTop:"",border:'0px solid red',display:'flex',flexDirection:"row",width:"70%"}}>				
					
						<div style={{width:'25%'}}> 
							<img src={logo} style={{width:'50px'}} />
							<br />
							<b>WINNING PARTNER</b>
							<br /><br />
							
							
							<a style={{color:'black',textDecoration:'none'}} 
									target="_blank" href='https://www.facebook.com/people/Winning-Partner-Software-Development-Limited/100087641685881/?mibextid=LQQJ4d'>
								<AiFillFacebook size={25} />
							</a>
							
							<a style={{color:'black',textDecoration:'none'}} target="_blank" href='https://www.instagram.com/wpsdhk/'>
							<AiFillInstagram size={25} />
							</a>
							
						</div>
						{/*
						<div style={{display:'flex',flexDirection:'column',width:'25%'}}> 
							<div className="bottom-title" >NAVIGATION</div>	
							<div style={{width:"20px",borderTop:'3px solid black'}}>&nbsp;</div>
							<div>Portfolio </div>						
							<div>About Us </div>
							<div>Contact Us </div>
						</div>
						*/}
						
						<div style={{display:'flex',flexDirection:'column',width:'40%'}}> 
							<div className="bottom-title" >{lang['m_contact'][state.lang]}</div>						
							<div style={{width:"20px",borderTop:'3px solid black'}}>&nbsp;</div>
							<div style={{display:'flex',flexDirection:'row'}}>
								<div style={{fontWeight:'bold',width:"10%"}}>T: </div> 
								<div>3618-7960</div>
							</div>
							
							<div style={{display:'flex',flexDirection:'row'}}>
								<div style={{fontWeight:'bold',width:"10%"}}>E: </div> 
								<div><a style={{color:'black',textDecoration:'none'}} href='mailto:sales@wpsdhk.com'>sales@wpsdhk.com</a></div>
							</div>
							
							<div style={{display:'flex',flexDirection:'row'}}>
								<div style={{fontWeight:'bold',width:"10%"}}>IG: </div> 
								<div><a style={{color:'black',textDecoration:'none'}} target="_blank" href='https://www.instagram.com/wpsdhk/'>@wpsdhk</a></div>
							</div>
							
							<div style={{display:'flex',flexDirection:'row'}}>
								<div style={{fontWeight:'bold',width:"10%"}}>FB: </div> 
								<div><a style={{color:'black',textDecoration:'none'}} 
									target="_blank" href='https://www.facebook.com/people/Winning-Partner-Software-Development-Limited/100087641685881/?mibextid=LQQJ4d'>
								Link </a></div>
								
							</div>						
						</div>
						
						
						<div style={{display:'flex',flexDirection:'column',width:'25%'}}> 
							<div className="bottom-title" >{lang['m_addr_title'][state.lang]}</div>												
							<div style={{width:"20px",borderTop:'3px solid black'}}>&nbsp;</div>
							<div style={{display:'flex',flexDirection:'row'}}>							
								<div> {lang['m_addr_content'][state.lang]} </div>
							</div>						
						</div>					
						
					</div>	

					<div style={{marginTop:"60px"}}>
						Copyrights © Winning Partner Software Development Ltd. All rights reserved.
					</div>
				</div>
			</div>
			
			<div className='mobile-div' style={{padding:"20px"}}>
				<div style={{display:'flex',flexDirection:'column',width:'100%'}}> 
					<div className="bottom-title" >{lang['m_contact'][state.lang]}</div>						
					<div style={{width:"20px",borderTop:'3px solid black'}}>&nbsp;</div>
					<div style={{display:'flex',flexDirection:'row',width:"100%"}}>
						<div style={{fontWeight:'bold',width:"10%"}}>T: </div> 
						<div >3618-7960</div>
					</div>
					
					<div style={{display:'flex',flexDirection:'row'}}>
						<div style={{fontWeight:'bold',width:"10%"}}>E: </div> 
						<div><a style={{color:'black',width:"70%",textDecoration:'none'}} href='mailto:sales@wpsdhk.com'>sales@wpsdhk.com</a></div>
					</div>
					
					<div style={{display:'flex',flexDirection:'row'}}>
						<div style={{fontWeight:'bold',width:"10%"}}>IG: </div> 
						<div><a style={{color:'black',textDecoration:'none'}} target="_blank" href='https://www.instagram.com/wpsdhk/'>@wpsdhk</a></div>
					</div>
					
					<div style={{display:'flex',flexDirection:'row'}}>
						<div style={{fontWeight:'bold',width:"10%"}}>FB: </div> 
						<div><a style={{color:'black',textDecoration:'none'}} 
							target="_blank" href='https://www.facebook.com/people/Winning-Partner-Software-Development-Limited/100087641685881/?mibextid=LQQJ4d'>
						Link </a></div>
						
					</div>	
				</div>
				
				<br/>
				
				<div style={{display:'flex',flexDirection:'column',width:'100%'}}> 
							<div className="bottom-title" >{lang['m_addr_title'][state.lang]}</div>												
							<div style={{width:"20px",borderTop:'3px solid black'}}>&nbsp;</div>
							<div style={{display:'flex',flexDirection:'row'}}>							
								<div> {lang['m_addr_content'][state.lang]} </div>
							</div>						
						</div>	
						
				<div style={{color:"#C0C0C0",marginTop:"30px"}}>
					Copyrights © Winning Partner Software Development Ltd. All rights reserved.
				</div>
				
				<br />
				
			</div>			
		</>
			
	);
}

export default App;
