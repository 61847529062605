import './App.css';
import {useState,useEffect,useRef,useContext} from 'react';
import { AppContext } from './context';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import { AiFillFacebook , AiFillInstagram } from 'react-icons/ai';

import { AiOutlineMenu } from 'react-icons/ai';

import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { blue } from './config';
import { useNavigate } from "react-router-dom";
import Button1 from './Button1';
import logo from './img/logo.png';

import {lang} from './lang';

function App({pic1,vh,showDetail,txt}) {	
	
	const navigate = useNavigate();	
	const [y,setY] = useState(window.scrollY);	
	
	const [loaded1,setLoaded1] = useState(false);
	const [loaded0,setLoaded0] = useState(false);	
	const {state,dispatch} = useContext(AppContext);	
	const [mobileMenu,setMobileMenu] = useState(false);
	const controlDirection = () => {
		setY(window.scrollY);
	}	

	useEffect(() => {
		window.addEventListener('scroll', controlDirection);
		return () => {
			window.removeEventListener('scroll', controlDirection);
		};
	},[]);
	
	useEffect(()=>{
		console.log(y);
	},[y]);
	
	useEffect(()=>{
		setTimeout(()=>{
			window.scrollTo(0, 0);
			setLoaded1(true);
		},1000);	
		setTimeout(()=>{
			window.scrollTo(0, 0);
			setLoaded0(true);
		},500);		
	},[]);
	
	const change_lang = (l) => {			
		dispatch({type:'lang',lang:l});
		const url = new URL(window.location);
		url.searchParams.set('lang', l);	
		window.history.pushState({}, '', url);
	}
	
	const parameter = () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		if (!!urlParams.get('lang')){
			return '?lang=' + urlParams.get('lang');
		}
		return '';
	}
		
	return (<>		
			<div className='desktop-div'>
			
				<div className={loaded1?'curtain-style2':'curtain-style1'}style={{zIndex:2,backgroundColor:"black",
								position:'fixed',
								width:"100%",					
								}} >						
									<div className={loaded0?'opac1':'opac2'} style={{
												borderBottom:"1px solid white",
												display:'flex',
												height:"100%",
												alignItems:'flex-end',
												justifyContent:'center',
												color:'white',
												paddingBottom:'30px',
												fontSize:"30px",}} > 
										
										<div className={(loaded0?'go-up-down ':'go-up') } >Winning Partner</div>
									</div>						
				</div>
					
				<div className={loaded1?'curtain-style3':'curtain-style4'}
									style={{zIndex:2,
									backgroundColor:"black",
									position:'fixed',
									width:"100%"}} >
									
					<div className={loaded0?'opac1':'opac2'} 
							style={{fontSize:"30px",
									color:'white',
									justifyContent:'center',
									display:'flex',
									paddingTop:'30px',				
									}} >		
								<div className={(loaded0?'go-up-down ':'go-down') } >Loading</div>
					</div>
				</div>
		
				{<div className={y<100?'bar1-style1':'bar1-style2'} style={{
							width:"100%",																							
							position:'fixed',
							display:'flex',
							flexDirection:'row',
							alignItems:'center',						
							justifyContent:'space-between'}}>
					<div style={{fontSize:"20px",paddingLeft:"100px"}} ><img src={logo} style={{width:"40px"}} /> Winning Partner</div>
					<div>
						<div style={{display:'flex',justifyContent:'flex-end',fontSize:"18px"}}>				
							<span style={{cursor:'pointer'}} onClick={()=>  { navigate("/index" + parameter() , { replace: true }) } }  >{lang['m_home'][state.lang]}</span>
							<span className='bar1'>|</span> 							
							
							<span style={{cursor:'pointer'}} onClick={()=>  { navigate("/service" + parameter() , { replace: true }) } }  >{lang['m_service'][state.lang]}</span>
							<span className='bar1'>|</span>								
							<span style={{cursor:'pointer'}} onClick={()=>  { navigate("/server" + parameter() , { replace: true }) } }  >{lang['m_server'][state.lang]}</span>
							<span className='bar1'>|</span>		
													
							{/*
							<span style={{cursor:'pointer'}} onClick={()=>  { navigate("/ip1" + parameter() , { replace: true }) } }  >{lang['m_ip1'][state.lang]}</span>
							<span className='bar1'>|</span>								
							*/}
							
							<span style={{cursor:'pointer'}} onClick={()=>  { navigate("/contactus" + parameter() , { replace: true }) } }  >{lang['m_contact'][state.lang]}</span>
							<span className='bar1'>|</span>							
							<span style={{cursor:'pointer'}} onClick={()=>  { window.open("/login",'_blank') } }  >{lang['m_login'][state.lang]}</span>
							
							&nbsp;&nbsp;
							&nbsp;&nbsp;
							<span style={{cursor:'pointer'}} onClick={()=>  { change_lang('chi'); }} >中</span>
							&nbsp;|&nbsp;
							<span style={{cursor:'pointer'}} onClick={()=>  { change_lang('eng'); }} >EN</span>
									
							<span className='bar1'>&nbsp;</span>						
							
						</div>
					</div>
				</div> }
		
				<div style={{display:"flex",flexDirection:"row",height:vh,border:"0px solid black"}}>		
				
					<div style={{width:"20%",backgroundColor:blue}}>
						
						<div style={{display:"flex",flexDirection:'column',
									justifyContent:'flex-end',
									color:'white',
									marginLeft:'30px',
									fontSize:"20px",
									height:"60px",								
									border:"0px solid black"}} >
							&nbsp;
						</div>
						
					</div>
					
					<div style={{width:"80%",
						backgroundImage:`url(${pic1})`,
						backgroundRepeat:'no-repeat',					
						backgroundSize:'cover',
						display:"flex",
						flexDirection:"column",
						justifyContent:"space-between",
						}}>										
						
						<div> </div>					
						
						<div style={{display:"flex",
								flexDirection:"column",
								justifyContent:"center",
								}}>	
								
							{showDetail && <div style={{display:"flex",flexDirection:'row',marginLeft:"-10%",fontSize:"10px",color:"white"}}>												
								<div style={{marginRight:"20px",marginTop:"7px",backgroundColor:"#C0C0C0",width:"50px",height:"1px"}} >&nbsp;</div>							
								<div>Winning Partner</div>
								</div>}
							
							<div style={{fontWeight:"bold",marginLeft:"-10%",fontSize:"80px",color:"white"}}>					
								{txt}
							</div>
							{showDetail && <div style={{marginLeft:"-10%"}} onClick={()=> { navigate("/contactus" + parameter(), { replace: true }) }} >						
								<Button1 width={'13%'} txt={lang['m_contact'][state.lang]} />						
							</div>}            
							
						</div>		
						
						<div>
						</div>
						
					</div>	
				</div>
			</div>
			
			
			
			<div className='mobile-div' style={{zIndex:9999,border:"0px solid red"}}>
			
				<div className={loaded1?'curtain-style2':'curtain-style1'}style={{zIndex:2,backgroundColor:"black",
								position:'fixed',
								width:"100%",					
								}} >						
									<div className={loaded0?'opac1':'opac2'} style={{
												borderBottom:"1px solid white",
												display:'flex',
												height:"100%",
												alignItems:'flex-end',
												justifyContent:'center',
												color:'white',
												paddingBottom:'30px',
												fontSize:"30px",}} > 
										
										<div className={(loaded0?'go-up-down ':'go-up') } >Winning Partner</div>
									</div>						
				</div>
					
				<div className={loaded1?'curtain-style3':'curtain-style4'}
									style={{zIndex:2,
									backgroundColor:"black",
									position:'fixed',
									width:"100%"}} >
									
					<div className={loaded0?'opac1':'opac2'} 
							style={{fontSize:"30px",
									color:'white',
									justifyContent:'center',
									display:'flex',
									paddingTop:'30px',				
									}} >		
								<div className={(loaded0?'go-up-down ':'go-down') } >Loading</div>
					</div>
				</div>
			
				<div style={{zIndex:9999,position:'fixed',width:'100%'}}>
					<div style={{display:'flex',
								alignItems:'center',
								justifyContent:'space-between',
								width:"100%",
								color:'white',
								fontSize:"20px",
								height:"8vh",
								backgroundColor:blue}}> 	
						
							<div style={{visibility:'hidden'}} onClick={()=>setMobileMenu(!mobileMenu)}>
								<AiOutlineMenu 
									style={{marginLeft:'30px',color:'white'}} size={30}
								/>						
							</div>					
							
							<div>Winning Partner</div>
							
							<div onClick={()=>setMobileMenu(!mobileMenu)}>
								<AiOutlineMenu 
									style={{marginRight:'30px',color:'white'}} size={30}
								/>						
							</div>
					</div>
					
					<div  >
						<div stylie={{display:'flex',flexDirection:'column'}}>
							<div className={mobileMenu?'mobile-menu-show':'mobile-menu-hide'}style={{backgroundColor:blue}} onClick={()=>  { setMobileMenu(false);navigate("/index" + parameter() , { replace: true }) } }  >{lang['m_home'][state.lang]}</div>
							
							<div className={mobileMenu?'mobile-menu-show':'mobile-menu-hide'}style={{backgroundColor:blue}} onClick={()=>  { setMobileMenu(false);navigate("/service" + parameter() , { replace: true }) } }  >{lang['m_service'][state.lang]}</div>
							
							<div className={mobileMenu?'mobile-menu-show':'mobile-menu-hide'}style={{backgroundColor:blue}} onClick={()=>  { setMobileMenu(false);navigate("/server" + parameter() , { replace: true }) } }  >{lang['m_server'][state.lang]}</div>
							
							{/*
							<div className={mobileMenu?'mobile-menu-show':'mobile-menu-hide'}style={{backgroundColor:blue}} onClick={()=>  { setMobileMenu(false);navigate("/ip1" + parameter() , { replace: true }) } }  >{lang['m_ip1'][state.lang]}</div>						
							*/}
							
							<div className={mobileMenu?'mobile-menu-show':'mobile-menu-hide'}style={{backgroundColor:blue}} onClick={()=>  { setMobileMenu(false);navigate("/contactus" + parameter() , { replace: true }) } }  >{lang['m_contact'][state.lang]}</div>
							
							<div className={mobileMenu?'mobile-menu-show':'mobile-menu-hide'}style={{backgroundColor:blue}} onClick={()=>  { window.open("/login",'_blank') } }  >{lang['m_login'][state.lang]}</div>
													
							<div className={mobileMenu?'mobile-menu-show':'mobile-menu-hide'} style={{ backgroundColor:blue,display:'flex',flexDirectin:"row",cursor:'pointer'}} >
								<div onClick={()=> { setMobileMenu(false);change_lang('chi'); }} >中</div>
								&nbsp;&nbsp;&nbsp;
								<div onClick={()=> { setMobileMenu(false);change_lang('eng'); }} >EN</div>
							</div>
																
							<div className='bar1'>&nbsp;</div>	
						</div>
					</div>
				</div>
				
				<div style={{height:"8vh"}}> &nbsp;</div>
				
				{txt.toUpperCase().indexOf('BUSINESS')==-1 && <div style={{fontSize:"20px",padding:"15px"}}>
					{txt}
				</div>}
				
			</div>
		
			
		</>
			
	);
}

export default App;
