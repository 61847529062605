import logo from './finalbee-01.png';
import './App.css';
import {useState,useEffect,useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { URL0,blue,linefee } from './config';
import { useNavigate } from "react-router-dom";

function App({updatedata}) {
	
	const [name,setName] = useState('');	
	const navigate = useNavigate();
	useEffect(()=>{	
		
		fetch(URL0 + '/post.php?action=readdata', {
			method: 'POST',
			body: '',
			headers: {			
			},
		})
		.then((res) => res.text())
		.then((data) => {
			console.log(data);
			data = JSON.parse(data);			
			if (data['result']){				
				setName(data['name']);
				if (updatedata) updatedata(data);
			}else{
				//navigate("/login",{replace:true});
			}
			//console.log(data);
		})
		.catch((err) => console.error(err));		
		
	},[]);
	
	const logout = () => {
		fetch(URL0 + '/post.php?action=logout', {
			method: 'POST',
			body: '',
			headers: {			
			},
		})
		.then((res) => res.json())
		.then((data) => {
			//console.log(data.text());
			if (data['result']){				
				navigate("/login",{replace:true});
			}
			//console.log(data);
		})
		.catch((err) => console.error(err));	
	}
		
	return (
		<>
			<div className="desktop-div">
				<div className='f-s-b' style={{marginBottom:"20px",border:"0px solid black"}}>	
						<div>&nbsp;</div>
						<div style={{fontSize:"14px"}}>
						
								<span style={{cursor:'pointer'}} onClick={()=>navigate("/hardware",{replace:true})} >Hardware </span>
								<span style={{color:"#D0D0D0",marginLeft:"10px",marginRight:"10px"}} >|</span> 
						
								<span style={{cursor:'pointer'}} onClick={()=>navigate("/ip",{replace:true})} >IP Service </span>
								<span style={{color:"#D0D0D0",marginLeft:"10px",marginRight:"10px"}} >|</span> 
								<span style={{cursor:'pointer'}} onClick={()=>navigate("/machine",{replace:true})} >Public Cloud Service </span>
								<span style={{color:"#D0D0D0",marginLeft:"10px",marginRight:"10px"}} >|</span> 
								
								<span style={{cursor:'pointer'}} onClick={logout} >Logout </span>
								</div>				
				</div>
			</div>
			
			
			<div className="mobile-div">
				<div style={{marginBottom:"20px",border:"0px solid black"}}>							
					<div style={{display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
						<div style={{fontSize:"14px"}}>
							
							<span style={{cursor:'pointer'}} onClick={()=>navigate("/hardware",{replace:true})} >Hardware </span>
							<span style={{color:"#D0D0D0",marginLeft:"10px",marginRight:"10px"}} >|</span> 
						
							<span style={{cursor:'pointer'}} onClick={()=>navigate("/ip",{replace:true})} >IP Service </span>
							<span style={{color:"#D0D0D0",marginLeft:"10px",marginRight:"10px"}} >|</span> 
							<span style={{cursor:'pointer'}} onClick={()=>navigate("/machine",{replace:true})} >Cloud Service </span>
							<span style={{color:"#D0D0D0",marginLeft:"10px",marginRight:"10px"}} >|</span> 
							
							<span style={{cursor:'pointer'}} onClick={logout} >Logout </span>
						</div>		
					</div>
					
				</div>
			</div>
		</>
	);
}

export default App;
