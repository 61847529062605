//import logo from './finalbee-01.png';
import './App.css';

import {useState,useEffect,useRef,useReducer} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppContext,AppState,AppReducer } from './context';
import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import { AiFillFacebook , AiFillInstagram } from 'react-icons/ai';

import {lang} from './lang';
import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { blue,URL } from './config';
import Header from './Header';
import Button1 from './Button1';
import { useNavigate } from "react-router-dom";
import Footer from './Footer';
import pic1 from './img/solution.jpeg';
import solution from './img/solution.jpeg';
import linepic from './img/line.jpeg';
import ebiz from './img/ebiz2.jpeg';
import macpic from './img/mac.jpeg';
import designpic from './img/design.jpeg';

import logo from './img/logo.png';
import picserver from './img/server1.jpeg';
import iconhtm from './img/icon_htm.png';
import iconcloud from './img/icon_cloud.png';
import icondev from './img/icon_dev.png';
import iconsys from './img/icon_sys.png';

import webc from './img/webc.jpeg';
import hyperv from './img/cluster.png';


function App() {	
	
	useEffect(()=>{		
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);		
		if (!!urlParams.get('lang')){
			dispatch({type:'lang',lang:urlParams.get('lang')});
		}
	},[]);	
	
	const [state,dispatch] = useReducer(AppReducer,AppState);
	const [k,setK] = useState(0);
	const [listitem,setListitem] = useState([]);
	const [scenes,setScenes] = useState([]);
	
	useEffect(()=>{		
		let tmp = [];
		for(let i=1;i<=8;i++){			
			tmp.push({title:lang['m_server_' + i + '_title'][state.lang],
						content:lang['m_server_' + i + '_content'][state.lang]
					});	
					
		}
		setListitem(tmp);
		//console.log('xxx',state.lang,tmp);
				
		let tmp2 = [];		
		for (let i=1;i<=4;i++){
			tmp2.push({title:lang['m_server3_'+i][state.lang],
					content1:lang['m_server2_' + i + '_title'][state.lang],
					content2:lang['m_server2_' + i + '_content'][state.lang],
					parameter:lang['m_server3_'+i]['parameter']
					});
		}	
		console.log('---------------------------');
		console.log(tmp2);
		setScenes(tmp2);
		
	},[state.lang]);
	
	return (<>	
			<AppContext.Provider value={{state, dispatch}}>
			
			<Header txt={lang['m_server'][state.lang]} showDetail={false} pic1={pic1} vh={'50vh'} />
			
			<div className='desktop-div'>
				<br />
				<div style={{width:"100%",display:'flex',flexDirection:'row',justifyContent:'center'}}>
					<div style={{marginBottom:"10px",fontSize:'25px',width:"80%",flexWrap:'wrap',display:'flex'}}>
					
						<div 
							style={{
							height:"40vh",
							width:'100%',
							backgroundColor:blue,
							alignItems:"flex-start",
							justifyContent:'center',
							padding:'20px',
							fontSize:"40px",								
							flexDirection:'row',
							marginBottom:"20px",
							backgroundSize:'cover',
							display:"flex",							
							}}>	
							<div style={{height:'100%',border:"0px solid black",display:'flex',flexDirection:'column',justifyContent:'center'}}>
								<div style={{color:'white'}} >{lang['m_serverhk'][state.lang]}</div>								
								<div style={{color:'white',fontSize:"15px"}} >{lang['m_server_desc'][state.lang]}</div>
								<div onClick={()=> window.open("/login",'_blank') }  
									style={{color:'white',
												fontSize:"13px",
												marginTop:'10px',
												width:'100px',												
												padding:'5px 15px 5px 15px',
												cursor:'pointer',
												backgroundColor:'orange'}}>Buy Now</div>
							</div>
							
							<div  style={{height:"100%"}}>
								<img src={hyperv} style={{height:"100%"}} /> 
							</div>							
						</div>
								
						
						<br />
					
						{lang['m_server_misc_1'][state.lang]}<br />
					</div>
				</div>
				
				{[[0,1,2,3],[4,5,6,7]].map(y=><div style={{width:"100%",display:'flex',flexDirection:'row',justifyContent:'center'}}>
					
					<div style={{width:"80%",flexDirection:'row',justifyContent:'space-between',display:'flex'}}>
						{listitem.length>0 && y.map((i)=>{
							return <div style={{display:'flex',
									alignItems:'center',
									flexDirection:'column',
									justifyContent:'flex-start',
									margin:"3px 3px 3px 3px",
									width:"25%",
									paddingBottom:"20px",
									outline:"1px solid #D0D0D0"}}>
							
								<div style={{marginTop:'20px'}} className={'server-background-image server-background-image-' + i}>					
									
								</div>
								<br />
								<div style={{border:"0px solid black",color:blue,fontWeight:'bold'}}>{listitem[i]['title']}</div>
								
								<br />
								<div style={{padding:'10px',border:"0px solid black"}}>{listitem[i]['content']}</div>
							
							</div>
						})}
					</div>
					<br />				
				</div>)}				
			</div>
			
		
			<div className='mobile-div'>
											
				<div 
					style={{					
					width:'100%',					
					alignItems:"flex-start",
					justifyContent:'center',
					padding:'10px',
					fontSize:"20px",								
					flexDirection:'row',
					marginBottom:"20px",
					backgroundSize:'cover',
					display:"flex",							
					}}>	
					<div style={{height:'100%',width:'100%',border:"0px solid black",display:'flex',flexDirection:'column',justifyContent:'center'}}>
						<div style={{}} >{lang['m_serverhk'][state.lang]}</div>														
						<div style={{fontSize:"14px"}} >{lang['m_server_desc'][state.lang]}</div>
						<div onClick={()=> window.open("/login",'_blank') }   style={{marginTop:'10px',fontSize:'14px',textAlign:'center',color:'white',padding:'5px 15px 5px 15px',cursor:'pointer',backgroundColor:'orange'}} > Buy Now </div>
					</div>				
				</div>
				
				<br />
				
				<div style={{paddingLeft:"10px",marginBottom:"10px",width:"100%"}}>
					<div style={{paddingLeft:"10px",borderLeft:"4px solid "+blue,fontSize:'25px'}}>
						{lang['m_server_misc_1'][state.lang]}<br />
					</div>
				</div>
			
			
				{listitem.map((x,i)=><div style={{padding:'10px',paddingBottom:"5px",paddingTop:"5px"}}>
									<div style={{display:'flex',
											alignItems:'center',
											flexDirection:'row',
											justifyContent:'flex-start',									
											width:"100%",
											paddingBottom:"10px",
											border:"1px solid #D0D0D0"}}>
												<div >												
													<div style={{transform:"scale(0.7)"}}
														className={'server-background-image server-background-image-' + i}>
													</div>
												</div>
												<div>
													<div style={{padding:'10px',border:"0px solid black",color:blue,fontWeight:'bold'}}>{listitem[i]['title']}</div>											
													
													<div style={{padding:'10px',border:"0px solid black"}}>{listitem[i]['content']}</div>																				
												</div>
										</div>						
								</div>		
								)}			
			</div>			
			
			<br />
			
			<div className='desktop-div'>
			
				<div style={{width:"100%",display:'flex',flexDirection:'row',justifyContent:'center'}}>
					<div style={{marginBottom:"10px",fontSize:'25px',width:"80%",flexWrap:'wrap',display:'flex'}}>
						{lang['m_server_misc_2'][state.lang]}<br />
					</div>
				</div>
				<div style={{width:"100%",display:'flex',flexDirection:'row',justifyContent:'center'}}>
					<div style={{width:"80%",border:"1px solid #C0C0C0",flexWrap:'wrap',display:'flex'}}>
						<div style={{border:"0px solid black",width:"100%",display:'flex',flexDirection:'row'}}>
							<div style={{width:"20%",display:'flex',flexDirection:'column',}}>					
								{scenes.map((item,i)=>{
									return <div style={{display:'flex',
														cursor:'pointer',
														border:"1px solid #F0F0F0",
														flexDirection:'column',
														alignItems:'center',
														backgroundColor:(k==i?'#F0F0F0':"#E0E0E0"),
														borderLeft:'6px solid ' + (k==i?blue:'#C0C0C0'),
														justifyContent:'center',height:'90px',
														}}
												onMouseOver={()=> setK(i) }		
														> 
											{item['title']}							
											</div>
								})}
							</div>
							
							{scenes.length>0 && <div style={{width:"80%",border:"0px solid red",display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
							
									<div style={{padding:"20px 20px 20px 20px"}}>
										<div style={{color:blue,fontWeight:'bold',fontSize:'20px'}}>{scenes[k]['content1']}</div>
										<br />										
										{scenes[k]['content2'].split('<br>').map(x=><div>{x}</div>)}
									</div>
									
									<div style={{padding:'20px',display:'flex',flexDirection:'row'}}>
									
										{lang['m_server_misc_3'][state.lang]} : &nbsp;&nbsp;
										<div className='grid1' >
											<div style={{color:'red',fontSize:"20px"}}>{scenes[k]['parameter']} core</div>
											<div className="grid2">CPU</div>
										</div>
										
										<div className='grid1'>
											<div style={{color:'red',fontSize:"20px"}}>{scenes[k]['parameter']} G</div>
											<div className="grid2">RAM</div>
										</div>
										
										<div className='grid1'>
											<div style={{color:'red',fontSize:"20px"}}>5M</div>
											<div className="grid2">BandWidth</div>
										</div>
										
										<div className='grid1'>
											<div style={{color:'red',fontSize:"20px"}}>5/20G</div>
											<div className="grid2">Guard</div>
										</div>
										
										<div className='grid1'>
											<div style={{color:'red',fontSize:"20px"}}>IO SpeedUp</div>
											<div className="grid2">Free</div>
										</div>
										<div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
											<div onClick={()=> window.open("/login",'_blank') }   style={{color:'white',padding:'5px 15px 5px 15px',cursor:'pointer',backgroundColor:'orange'}} > Buy Now</div>
										</div>
										
									</div>
							</div>}
						</div>
					</div>			
				</div>	
			</div>
			
			<div className='mobile-div'>
							
				<div style={{paddingLeft:"10px",marginBottom:"10px",width:"100%"}}>
					<div style={{paddingLeft:"10px",borderLeft:"4px solid "+blue,fontSize:'25px'}}>
						{lang['m_server_misc_2'][state.lang]}<br />
					</div>
				</div>
				
				{scenes.map(item=>{
					return <div style={{padding:"10px",paddingTop:"5px",paddingBottom:"5px"}}>
								<div style={{border:"1px solid #E0E0E0"}}>
									<div style={{padding:"10px",width:"100%",
											backgroundColor:"#E0E0E0"}}> {item['title']} </div>
									<div style={{padding:"10px 10px 10px 10px"}}>
										{item['content1']}										
										<br /><br />									
										{item['content2'].replace('<br>','')}
									</div>
									
									<br />
									<div style={{paddingLeft:"10px"}}> {lang['m_server_misc_3'][state.lang]} </div>
									<div style={{padding:'20px',display:'flex',flexDirection:'column'}}>									
											
										<div >CPU {item['parameter']} Core</div>										
										<div> {item['parameter']} G RAM </div>
										
										<div>								
											5M BandWidth														
										</div>
										<div>								
											5/20G Guard
										</div>
										<br />
										<div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
											<div onClick={()=> window.open("/login",'_blank') }											
												style={{color:'white',padding:'5px 15px 5px 15px',cursor:'pointer',backgroundColor:'orange'}} >Buy Now</div>
										</div>
										
									</div>
								</div>
							</div>
				})}
				
				
			</div>
			<br />
			<br />			
				
			
			<Footer />					
		
			</AppContext.Provider>
		</>
			
	);
}

export default App;
