import logo from './img/logo.png';

function App() {	

	return (<div style={{alignItems:'center',display:'flex',flexDirection:'row'}} >
			<img src={logo} style={{width:"100px"}} />
			<div><h2> WINNING PARTNER</h2>				</div>
		</div>			
	);
}

export default App;
