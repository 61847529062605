import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Front from './Front';
import Service from './Service';
import Contactus from './Contactus';

import Reg from './Reg';
import Login from './Login';
import Machine from './Machine';
import IP from './IP';
import IP1 from './IP1';
import Backend from './Backend';
import Server from './Server';

import Hardware from './Hardware';

import { BrowserRouter,Routes,Route,Link,useParams  } from "react-router-dom";
//https://github.com/remix-run/react-router/blob/main/docs/getting-started/tutorial.md
const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
	<BrowserRouter>
		<Routes>
			<Route path={'/'} element={<Front />} />			
			<Route path={'/index'} element={<Front />} />		
			<Route path={'/service'} element={<Service />} />		
			<Route path={'/contactus'} element={<Contactus />} />	
			<Route path={'/server'} element={<Server />} />	
			{/*<Route path={'/ip1'} element={<IP1 />} />	*/}
			
			<Route path={'/reg'} element={<Reg />} />		
			<Route path={'/login'} element={<Login />} />		
			<Route path={'/machine'} element={<Machine />} />		
			<Route path={'/ip'} element={<IP />} />		
			<Route path={'/backend'} element={<Backend />} />		
			<Route path={'/hardware'} element={<Hardware />} />		
		</Routes>
	</BrowserRouter>
	)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
