//import logo from './finalbee-01.png';
import './App.css';
import {useState,useEffect,useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import { AiFillFacebook , AiFillInstagram } from 'react-icons/ai';

import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { blue,URL } from './config';
import { useNavigate } from "react-router-dom";


function Button1({txt,width}) {
	return <div style={{border:"1px solid white",				
				borderRadius:"30px",		
				cursor:'pointer',
				paddingTop:"10px",
				paddingBottom:"10px",
				paddingLeft:"20px",
				paddingRight:"20px",
				fontSize:"10px",
				width:width,
				display:"flex",
				flexDorection:"row",
				alignItems:"center",
				color:"white"}}>					
		<span style={{									
				width:"30px",
				height:"30px",
				display:"inline-block",
				borderRadius:"15px",
				display:"flex",
				flexDirection:"column",
				justifyContent:"center",
				textAlign:"center",
				border:"1px solid white",
				color:blue,
				fontSize:"16px",
				fontWeight:"bold",
				backgroundColor:"white",
			}}
			> >
		</span>
		
		<span  style={{fontSize:"15px",border:"0px solid black",width:"80%",textAlign:'center'}}> {txt}</span>
	</div>						
}

export default Button1;
