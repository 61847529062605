//import logo from './finalbee-01.png';
import './App.css';
import {useState,useEffect,useRef,useReducer} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import { AiFillFacebook , AiFillInstagram } from 'react-icons/ai';
import { AppContext,AppState,AppReducer } from './context';
import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { blue } from './config';
import Header from './Header';
import Button1 from './Button1';
import { useNavigate } from "react-router-dom";

import pic1 from './img/girl.jpeg';
import solution from './img/solution.jpeg';
import linepic from './img/line.jpeg';
import macpic from './img/mac.jpeg';
import Footer from './Footer';
import logo from './img/logo.png';

import iconhtm from './img/icon_htm.png';
import iconcloud from './img/icon_cloud.png';
import icondev from './img/icon_dev.png';
import iconsys from './img/icon_sys.png';

import webc from './img/webc.jpeg';

import {lang} from './lang';

function App() {	
	
	const emailRef = useRef();	
	const passwordRef = useRef();
	const [msg,setMsg] = useState('');
	const [show1,setShow1] = useState(false);
	const navigate = useNavigate();
	const [redirect,setRedirect] = useState(false);
	const [state,dispatch] = useReducer(AppReducer,AppState);
	
	useEffect(()=>{		
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);		
		if (!!urlParams.get('lang')){
			dispatch({type:'lang',lang:urlParams.get('lang')});
		}		
	},[]);	
	
		
	return (<>
			<AppContext.Provider value={{state, dispatch}}>
	
			<Modal show={show1} onHide={()=>  { redirect?navigate("/machine", { replace: true }):setShow1(false) }    }>
				<Modal.Header closeButton>
				<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				{msg}
				</Modal.Body>
				 <Modal.Footer>
				<Button variant="secondary" onClick={()=>  { redirect?navigate("/machine", { replace: true }):setShow1(false) }  }>
				Close
				</Button>
				
				</Modal.Footer>
			</Modal>
			
			
			<Header showDetail={true} pic1={pic1} txt={"Your WINNING Business Partner"} vh={'100vh'} />
			
			{/* end first element */ }
			
			<div className='mobile-div'>
				<div 
					style={{
					height:"50vh",
					color:'white',
					alignItems:"center",
					justifyContent:'center',
					padding:'10px',
					fontSize:"50px",
					backgroundImage:`url(${pic1})`,
					backgroundRepeat:'no-repeat',	
					flexDirection:'row',
					marginBottom:"20px",
					backgroundSize:'cover',
					display:"flex",							
					}}>	
					Your WINNING Business Partner
					
				</div>
			</div>
			
			
			<div className='desktop-div'>
				<div style={{height:"60vh",display:"flex",flexDirection:"row",					
						alignItems:'center',					
						justifyContent:'center',
						border:"0px solid black"}}>		
					
					<div style={{width:"80%"}}>				
										
						<span style={{marginBottom:'20px',fontWeight:"bold",fontSize:"30px"}}> {lang['m_onestop'][state.lang]} </span>
						
						<div style={{display:'flex',flexDirection:'row'}}>
							<div className="Dx" style={{border:"0px solid green",alignItems:'center',display:"flex",flexDirection:'column'}}>
								<img src={iconcloud} style={{width:"200px"}} />
								<span style={{textAlign:'center',fontWeight:'bold'}}>{lang['m_onestop_1_title'][state.lang]}</span>							
								<span style={{padding:'20px',wordWrap:"break-word",wordBreak:"break-all"}}> {lang['m_onestop_1_content'][state.lang]}
								</span>							
							</div>
							
							
							<div className="Dx"style={{border:"0px solid green",alignItems:'center',display:"flex",flexDirection:'column'}}>
								<img src={iconhtm} style={{width:"200px"}} />
								<span style={{textAlign:'center',fontWeight:'bold'}}>{lang['m_onestop_2_title'][state.lang]} </span>							
								<span style={{padding:'20px',wordWrap:"break-word",wordBreak:"break-all"}}> {lang['m_onestop_2_content'][state.lang]}							
								</span>							
							</div>
							
							<div className="Dx"style={{border:"0px solid green",alignItems:'center',display:"flex",flexDirection:'column'}}>
								<img src={icondev} style={{width:"200px"}} />
								<span style={{textAlign:'center',fontWeight:'bold'}}>{lang['m_onestop_3_title'][state.lang]} </span>							
								<span style={{padding:'20px',wordWrap:"break-word",wordBreak:"break-all"}}> {lang['m_onestop_3_content'][state.lang]}
								</span>							
							</div>
							
									
							<div className="Dx" style={{border:"0px solid green",alignItems:'center',display:"flex",flexDirection:'column'}}>
								<img src={iconsys} style={{width:"200px"}} />
								<span style={{textAlign:'center',fontWeight:'bold'}}>{lang['m_onestop_4_title'][state.lang]} </span>							
								<span style={{padding:'20px',wordWrap:"break-word",wordBreak:"break-all"}}> {lang['m_onestop_4_content'][state.lang]}
								</span>							
							</div>												
						</div>	
					</div>				
				</div>
			</div>
			

			
			
			<div className='desktop-div'>
				<div style={{display:"flex",flexDirection:"row",					
						alignItems:'center',					
						justifyContent:'center',
						border:"0px solid black"}}>		
					
					<div style={{width:"80%"}}>				
										
						<span style={{marginBottom:'20px',fontWeight:"bold",fontSize:"30px"}}> {lang['aboutus_title'][state.lang]} </span>
						<br /><br />
						<div style={{display:'flex',flexDirection:'row'}}>
							
							<div style={{margin:'20px',width:'100%',display:"flex",flexDirection:"column"}}>
								{lang['aboutus'][state.lang].split("\n").map(i=><div>{i}<br/></div>)}
							</div>

							
						</div>	
					</div>				
				</div>
			</div>
			
			
			<div className='mobile-div'>
			
				<div style={{display:'flex',flexDirection:'column'}}>
					<div className="Dx" style={{border:"0px solid green",alignItems:'center',display:"flex",flexDirection:'column'}}>
						<img src={iconcloud} style={{width:"200px"}} />
						<span style={{fontWeight:'bold'}}>{lang['m_onestop_1_title'][state.lang]}</span>							
						<span style={{padding:'20px',wordWrap:"break-word",wordBreak:"break-all"}}> {lang['m_onestop_1_content'][state.lang]}
						</span>							
					</div>
					
					<div className="Dx"style={{border:"0px solid green",alignItems:'center',display:"flex",flexDirection:'column'}}>
						<img src={iconhtm} style={{width:"200px"}} />
						<span style={{fontWeight:'bold'}}>{lang['m_onestop_2_title'][state.lang]} </span>							
						<span style={{padding:'20px',wordWrap:"break-word",wordBreak:"break-all"}}> {lang['m_onestop_2_content'][state.lang]}							
						</span>							
					</div>
					
					<div className="Dx"style={{border:"0px solid green",alignItems:'center',display:"flex",flexDirection:'column'}}>
						<img src={icondev} style={{width:"200px"}} />
						<span style={{fontWeight:'bold'}}>{lang['m_onestop_3_title'][state.lang]} </span>							
						<span style={{padding:'20px',wordWrap:"break-word",wordBreak:"break-all"}}> {lang['m_onestop_3_content'][state.lang]}
						</span>							
					</div>					
							
					<div className="Dx" style={{border:"0px solid green",alignItems:'center',display:"flex",flexDirection:'column'}}>
						<img src={iconsys} style={{width:"200px"}} />
						<span style={{fontWeight:'bold'}}>{lang['m_onestop_4_title'][state.lang]} </span>							
						<span style={{padding:'20px',wordWrap:"break-word",wordBreak:"break-all"}}> {lang['m_onestop_4_content'][state.lang]}
						</span>							
					</div>												
				</div>	
				<br />
			</div>
			

			<div className='mobile-div'>
				<br /><br />
				<center><div> <b>{lang['aboutus_title'][state.lang]}</b> </div></center>			
				<div style={{display:'flex',flexDirection:'column'}}>
					<div style={{margin:'20px',width:'100%',display:"flex",flexDirection:"column"}}>
						{lang['aboutus'][state.lang].split("\n").map(i=><div>{i}<br/></div>)}
					</div>											
				</div>	
				<br />
			</div>
				
			{/* end second element */ }
			
			<div className='desktop-div'>
				<div 
					style={{width:"100%",
					height:"80vh",
					backgroundImage:`url(${webc})`,
					backgroundRepeat:'no-repeat',					
					backgroundSize:'cover',
					display:"flex",	
					flexDirection:'row',
					justifyContent:"space-between",
					}}>		

					<div   style={{width:"80%"}}>
					</div>
					
					<div className="res-front-3" style={{display:'flex',
								flexDirection:'column',							
								justifyContent:'center',
								alignItems:'center',
								backgroundColor:'rgba(0,0,0,0.5)'}}>							
								<div style={{width:"80%",border:"0px solid red"}}>								
									<div style={{marginBottom:"10px",fontWeight:'bold',fontSize:'40px',color:"white"}}> 
									{lang['m_feature_1_title'][state.lang]} </div>
									
									<div style={{marginBottom:"20px",color:'white'}}> 
										{lang['m_feature_1_content'][state.lang]}
									</div>									
																	
								</div>
							
					</div>
					<div style={{width:"10%"}}>
					</div>					
				</div>
			</div>
			
			<div className='mobile-div'>
				<div style={{width:"100%",
					height:"60vh",
					backgroundImage:`url(${webc})`,
					backgroundRepeat:'no-repeat',					
					backgroundSize:'cover',
					display:"flex",
					flexDirection:"row",
					justifyContent:"space-between",
					}}>		
				</div>
				
				<div className='mobile-div8'>
					<div> 
					{lang['m_feature_1_title'][state.lang]} </div>					
					<div > 
						{lang['m_feature_1_content'][state.lang]}
					</div>	
				</div>
			</div>
			
			{/* end third element */ }
			<br />
			
			
			<div className='desktop-div'>		
				<div 
					style={{width:"100%",
					height:"130vh",
					backgroundImage:`url(${macpic})`,
					backgroundRepeat:'no-repeat',	
					flexDirection:'row',
					backgroundSize:'cover',
					display:"flex",							
					}}>		

					<div style={{border:"0px solid red",width:"5%"}}>
						
					</div>
					
					<div className="res-front-3"
							style={{display:'flex',
								flexDirection:'column',							
								border:"0px solid green",
								justifyContent:'center',
								alignItems:'center',
								backgroundColor:'rgba(0,0,0,0.5)'}}>
								
								<div style={{width:"80%",border:"0px solid red"}}>								
									<div style={{marginBottom:"10px",fontWeight:'bold',fontSize:'40px',color:"white"}}> 
									{lang['m_feature_2_title'][state.lang]} </div>
									
									<div style={{marginBottom:"20px",color:'white'}}> 
										{lang['m_feature_2_content'][state.lang]}
									</div>									
									
									
								</div>
							
					</div>
					<div style={{width:"65%"}}>
						&nbsp;
					</div>
						
				</div>
			</div>
			
			<div  className='mobile-div'>
				<div style={{width:"100%",
					height:"60vh",
					backgroundImage:`url(${macpic})`,
					backgroundRepeat:'no-repeat',					
					backgroundSize:'cover',
					display:"flex",
					flexDirection:"row",
					justifyContent:"space-between",
					}}>		
				</div>
				
				<div className='mobile-div8'>
					<div > 
					{lang['m_feature_2_title'][state.lang]} </div>					
					<div > 
						{lang['m_feature_2_content'][state.lang]}
					</div>	
				</div>
			</div>
			
			<br />
			<div className='desktop-div'>
				<div style={{width:"100%",
					height:"80vh",
					backgroundImage:`url(${solution})`,
					backgroundRepeat:'no-repeat',					
					backgroundSize:'cover',
					display:"flex",
					flexDirection:"row",
					justifyContent:"space-between",
					}}>		

					<div style={{width:"60%"}}>
					</div>
					
					<div style={{display:'flex',
								flexDirection:'column',
								width:"30%",
								justifyContent:'center',
								alignItems:'center',
								backgroundColor:'rgba(0,0,0,0.5)'}}>
								
								<div style={{width:"80%",border:"0px solid red"}}>								
									<div style={{marginBottom:"10px",fontWeight:'bold',fontSize:'40px',color:"white"}}> 
									{lang['m_feature_3_title'][state.lang]} </div>
									
									<div style={{marginBottom:"20px",color:'white'}}> 
										{lang['m_feature_3_title'][state.lang]}
									</div>	
								</div>
							
					</div>
					<div style={{width:"10%"}}>
					</div>
						
				</div>
			</div>
			
			<div className='mobile-div'>
				<div style={{width:"100%",
					height:"40vh",
					backgroundImage:`url(${solution})`,
					backgroundRepeat:'no-repeat',					
					backgroundSize:'cover',
					display:"flex",
					flexDirection:"row",
					justifyContent:"space-between",
					}}>		
				</div>
				
				<div className='mobile-div8'>
					<div> 
					{lang['m_feature_3_title'][state.lang]} </div>					
					<div > 
						{lang['m_feature_3_content'][state.lang]}
					</div>	
				</div>
				
			</div>
			
			<br />
						
			<Footer />			
						
		</AppContext.Provider>
		</>
			
	);
}

export default App;
