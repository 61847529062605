//import logo from './finalbee-01.png';
import './App.css';
import {useState,useEffect,useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';

import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { URL0 } from './config';
import { useNavigate } from "react-router-dom";
import logo from './img/logo.png';
import BackendBanner from './BackendBanner';

function App() {
	
	let blue = "#1e81b0";
	const emailRef = useRef();
	
	const passwordRef = useRef();
	const [msg,setMsg] = useState('');
	const [show1,setShow1] = useState(false);
	const navigate = useNavigate();
	const [redirect,setRedirect] = useState(false);
		
	const login = () => {		
		
		let data = {
			email:emailRef.current.value,
			pwd:passwordRef.current.value
		};
		
		fetch(URL0 + '/post.php?action=login', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {			
			},
		})
		.then((res) => res.text())
		.then((data) => {
			//console.log(data);
			data = JSON.parse(data);
			if (data['result']==1){				
				setShow1(true);
				setMsg(data['msg']);
				setRedirect(false);
			}
			if (data['result']==2){				
				setMsg(data['msg']);
				setShow1(true);
				setRedirect(true);
			}
		})
		.catch((err) => console.error(err));
	}
	
	useEffect(()=>{		
				
	},[]);	
		
	return (<>	
	
			<Modal show={show1} onHide={()=>  { redirect?navigate("/machine", { replace: true }):setShow1(false) }    }>
				<Modal.Header closeButton>
				<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				{msg}
				</Modal.Body>
				 <Modal.Footer>
				<Button variant="secondary" onClick={()=>  { redirect?navigate("/machine", { replace: true }):setShow1(false) }  }>
				Close
				</Button>
				
				</Modal.Footer>
			</Modal>
	
			<div style={{
				marginTop:"10px",
				paddingTop:"20px",
				backgroundColor:"#FFFFFF",
				border:"0px solid black",								
				
				filter: "drop-shadow(1px 1px 5px #808080)",
				}} className="container-lg">
				
				<div style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
					{/* <div><input type='button' style={{border:"1px solid " + blue,color:blue,fontWeight:'bold'}} value=' Login ' /> </div>*/}				
				</div>				
			
				<BackendBanner />				
				
				<div className="mm1" style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"space-between"}}>
				
					<div style={{width:"100%",border:"0px solid black"}}>
					
						
						<hr style={{marginTop:"40px",marginBottom:"40px"}} />
												
						<div style={{border:"0px solid black",color:blue,fontSize:"20px"}} > Login </div>
						<br />
						
						
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1" >Email</span>
							<input ref={emailRef} type="text" className="mm5 form-control" placeholder="Email" 
							aria-describedby="basic-addon1" />
						</div>
						
						
						
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1" >Password</span>
							<input ref={passwordRef} type="text" className="mm5 form-control" placeholder="Password" 
							aria-describedby="basic-addon1" />
						</div>
						
						<br />
						
						<div style={{marginTop:"20px",marginBottom:"20px"}}>
							<input  style={{border:"2px solid "+blue,backgroundColor:blue,color:'white',width:"100%"}} type='button' value='Login' onClick={()=>login()} />
						</div>
						
						<br />
						<div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
						
							<div style={{marginTop:"20px",marginBottom:"20px",marginRight:'40px'}}>
								<span style={{cursor:'pointer'}} onClick={()=> navigate("/index", { replace: true })  } >  Back to Home Page</span>										 
							</div>
							
						
							<div style={{marginTop:"20px",marginBottom:"20px"}}>
								<span style={{cursor:'pointer'}} onClick={()=> navigate("/reg", { replace: true })  } >  Register a new account</span>										 
							</div>
						</div>
						
						
					</div>
					
				</div>		
				<br />				
			</div>
			
			<br />
		</>
			
	);
}

export default App;
