
let url;

//console.log(window.location.host);
if (window.location.host.indexOf('192.168.')!=-1 || window.location.host.indexOf('127.0.0.1')!=-1 || window.location.host.indexOf('localhost')!=-1){
	url = 'http://wpsdhk.com';
} else {
	url = window.location.protocol + '//' + window.location.host;
}

export const URL0 = url;
export const linefee = 2400;
export const onelinefree = 20;
export const blue = "#1e81b0";

