//import logo from './finalbee-01.png';
import './App.css';

import {useState,useEffect,useRef,useReducer} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppContext,AppState,AppReducer } from './context';
import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import { AiFillFacebook , AiFillInstagram } from 'react-icons/ai';

import {lang} from './lang';
import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { blue,URL0 } from './config';
import Header from './Header';
import Button1 from './Button1';
import { useNavigate } from "react-router-dom";
import Footer from './Footer';
import pic1 from './img/solution.jpeg';
import solution from './img/solution.jpeg';
import linepic from './img/line.jpeg';
import ebiz from './img/ebiz2.jpeg';
import macpic from './img/mac.jpeg';
import designpic from './img/design.jpeg';

import logo from './img/logo.png';

import iconhtm from './img/icon_htm.png';
import iconcloud from './img/icon_cloud.png';
import icondev from './img/icon_dev.png';
import iconsys from './img/icon_sys.png';

import webc from './img/webc.jpeg';


function App() {	
	
	useEffect(()=>{		
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);		
		if (!!urlParams.get('lang')){
			dispatch({type:'lang',lang:urlParams.get('lang')});
		}		
	},[]);	
	
	const [state,dispatch] = useReducer(AppReducer,AppState);
	const [show1,setShow1] = useState(false);	
	const [enquiry,setEnquiry] = useState({name:'',
											email:'',
											company:'',
											phone:'',
											opinion:''
											});
	
	function sendemail(){	
		let link = URL0 + '/post.php?action=enquiry';
		console.log(link);
		fetch(link, {
			method: 'POST',			
			body: JSON.stringify(enquiry),
			headers: {
				//contentType:"application/x-www-form-urlencoded"
			},
		})
		.then((res) => res.text())
		.then((data) => {
			console.log(data);
			data = JSON.parse(data);
			if (data['result']){				
				setShow1(true);
			}			
		})
		.catch((err) => console.error(err));
		
	}
		
	return (<>	
			<Modal show={show1} onHide={()=>  { setShow1(false) }    }>
				<Modal.Header closeButton>
				<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				多謝您的意見
				</Modal.Body>
				 <Modal.Footer>
				<Button variant="secondary" onClick={()=>  { setShow1(false)}}>
				Close
				</Button>
				
				</Modal.Footer>
			</Modal>
	
			<AppContext.Provider value={{state, dispatch}}>
					
			
			<Header txt={lang['m_contact'][state.lang]} showDetail={false} pic1={pic1} vh={'50vh'} />

			<br />
			<iframe 
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1845.5139022344792!2d114.21672127187948!3d22.314788183957763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340401485058b95b%3A0x9e265d66bbd83f06!2z5Yu15qWt6KGXOeiZn-WQjOWIqeW3pealreWkp-W7iA!5e0!3m2!1szh-TW!2shk!4v1676729362168!5m2!1szh-TW!2shk" 			
				style={{width:"100%",height:"400px"}} 
				allowfullscreen="" 
				loading="lazy" 
				referrerpolicy="no-referrer-when-downgrade">
			</iframe>
			
			<br /><br />
			
			
			<div className='desktop-div'>
				<div style={{display:"flex",flexDirection:'row',justifyContent:'center',}} >
				
					<div style={{width:"80%",outline:"0px solid red",display:"flex",flexDirection:'row'}} >
						<div style={{width:"50%"}}>
							<div  className='Chi'  style={{fontSize:"25px"}}>{lang['m_newproject_title'][state.lang]}</div> <br />
							{lang['m_newproject_content'][state.lang]}
							
							<div style={{border:"0px solid green",display:"flex",flexDirection:'column'}}>
							
								<div style={{marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<div style={{fontWeight:"bold",width:"20%"}}> {lang['m_opinion_tel'][state.lang]} </div>
									<div style={{width:"50%"}}>3618-7960 </div>
								</div>		
								
								<div style={{marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<div style={{fontWeight:"bold",width:"20%"}}> {lang['m_opinion_email'][state.lang]} </div>
									<div style={{width:"50%"}}><a style={{color:'black',textDecoration:'none'}} href='mailto:sales@wpsdhk.com'>sales@wpsdhk.com</a> </div>
								</div>
								
								<div style={{marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<div style={{fontWeight:"bold",width:"20%"}}> IG </div>
									<div style={{width:"50%"}}><a style={{color:'black',textDecoration:'none'}} target="_blank" href='https://www.instagram.com/wpsdhk/'>@wpsdhk</a> </div>
								</div>	
								
								<div style={{marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<div style={{fontWeight:"bold",width:"20%"}}> FB </div>
									<div style={{width:"50%"}}><a style={{color:'black',textDecoration:'none'}} 
									target="_blank" href='https://www.facebook.com/people/Winning-Partner-Software-Development-Limited/100087641685881/?mibextid=LQQJ4d'>
								Link </a> </div>
								</div>								
							</div>
						</div>					
						
						<div style={{width:"30%"}}>
							<div className='Chi' style={{fontSize:"25px"}}>{lang['m_opinion'][state.lang]}</div> <br />
							
							
							<div style={{border:"0px solid green",display:"flex",flexDirection:'column'}}>
							
								<div style={{marginBottom:"15px",marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<input type='text' value={enquiry['name']} onChange={(event)=> { setEnquiry({...enquiry,name:event.target.value}) } } placeholder={lang['m_opinion_name'][state.lang]} style={{width:"100%",outline:'none',border:'none',borderBottom:"1px solid black"}} /> 
								</div>		
								
								<div style={{marginBottom:"15px",marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<input type='text' value={enquiry['email']} onChange={(event)=> { setEnquiry({...enquiry,email:event.target.value}) }} placeholder={lang['m_opinion_email'][state.lang]} style={{width:"100%",outline:'none',border:'none',borderBottom:"1px solid black"}} /> 
								</div>	
								
								<div style={{marginBottom:"15px",marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<input type='text' value={enquiry['company']} onChange={(event)=> { setEnquiry({...enquiry,company:event.target.value}) }} placeholder={lang['m_opinion_company'][state.lang]} style={{width:"100%",outline:'none',border:'none',borderBottom:"1px solid black"}} /> 
								</div>	
								
								<div style={{marginBottom:"15px",marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<input type='text' value={enquiry['phone']} onChange={(event)=> { setEnquiry({...enquiry,phone:event.target.value}) }} placeholder={lang['m_opinion_tel'][state.lang]} style={{width:"100%",outline:'none',border:'none',borderBottom:"1px solid black"}} /> 
								</div>	
								
								
								<textarea value={enquiry['opinion']} onChange={(event)=> { setEnquiry({...enquiry,opinion:event.target.value}) }} rows='4' placeholder={lang['m_opinion_msg'][state.lang]} style={{marginBottom:"15px",marginTop:"15px"}}>
									
								</textarea>	
								
								<input type='button' onClick={()=>sendemail()} value={lang['m_opinion_submit'][state.lang]} /> 
														
							</div>
						</div>			
						
						<div>
						</div>	
						
					</div>			
				
				</div>	
			</div>	
			
			<div className='mobile-div'>
				<div style={{display:"flex",flexDirection:'column',justifyContent:'center',}} >
				
					
						<div style={{width:"100%",padding:"10px"}}>
							<div className='Chi'  style={{fontSize:"25px"}}>{lang['m_newproject_title'][state.lang]}</div> <br />
							{lang['m_newproject_content'][state.lang]}
							
							<div style={{border:"0px solid green",display:"flex",flexDirection:'column'}}>
							
								<div style={{marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<div style={{fontWeight:"bold",width:"30%"}}> {lang['m_opinion_tel'][state.lang]} </div>
									<div style={{width:"50%"}}>3618-7960 </div>
								</div>		
								
								<div style={{marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<div style={{fontWeight:"bold",width:"30%"}}> {lang['m_opinion_email'][state.lang]} </div>
									<div style={{width:"50%"}}><a style={{color:'black',textDecoration:'none'}} href='mailto:sales@wpsdhk.com'>sales@wpsdhk.com</a> </div>
								</div>
								
								<div style={{marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<div style={{fontWeight:"bold",width:"30%"}}> IG </div>
									<div style={{width:"50%"}}><a style={{color:'black',textDecoration:'none'}} target="_blank" href='https://www.instagram.com/wpsdhk/'>@wpsdhk</a> </div>
								</div>	
								
								<div style={{marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<div style={{fontWeight:"bold",width:"30%"}}> FB </div>
									<div style={{width:"50%"}}><a style={{color:'black',textDecoration:'none'}} 
									target="_blank" href='https://www.facebook.com/people/Winning-Partner-Software-Development-Limited/100087641685881/?mibextid=LQQJ4d'>
								Link </a> </div>
								</div>								
							</div>
						</div>					
						
						<br />
						<br />
						
						<div style={{width:"100%",padding:"10px"}}>
							<div className='Chi' style={{fontSize:"25px"}}>{lang['m_opinion'][state.lang]}</div> <br />
							
							<div style={{border:"0px solid green",display:"flex",flexDirection:'column'}}>
							
								<div style={{marginBottom:"15px",marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<input value={enquiry['name']} onChange={(event)=> { setEnquiry({...enquiry,name:event.target.value}) } } type='text' placeholder={lang['m_opinion_name'][state.lang]} style={{width:"100%",outline:'none',border:'none',borderBottom:"1px solid black"}} /> 
								</div>		
								
								<div style={{marginBottom:"15px",marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<input value={enquiry['email']} onChange={(event)=> { setEnquiry({...enquiry,email:event.target.value}) } }type='text' placeholder={lang['m_opinion_email'][state.lang]} style={{width:"100%",outline:'none',border:'none',borderBottom:"1px solid black"}} /> 
								</div>	
								
								<div style={{marginBottom:"15px",marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<input value={enquiry['company']} onChange={(event)=> { setEnquiry({...enquiry,company:event.target.value}) } }type='text' placeholder={lang['m_opinion_company'][state.lang]} style={{width:"100%",outline:'none',border:'none',borderBottom:"1px solid black"}} /> 
								</div>	
								
								<div style={{marginBottom:"15px",marginTop:"15px",display:"flex",flexDirection:'row'}}>								
									<input value={enquiry['phone']} onChange={(event)=> { setEnquiry({...enquiry,phone:event.target.value}) } } type='text' placeholder={lang['m_opinion_tel'][state.lang]} style={{width:"100%",outline:'none',border:'none',borderBottom:"1px solid black"}} /> 
								</div>	
																
								<textarea value={enquiry['opinion']} onChange={(event)=> { setEnquiry({...enquiry,opinion:event.target.value}) }} rows='4' placeholder={lang['m_opinion_msg'][state.lang]} style={{marginBottom:"15px",marginTop:"15px"}}>
									
								</textarea>	
								
								<input type='button' onClick={()=>sendemail()} value={lang['m_opinion_submit'][state.lang]} /> 
														
							</div>
						<div>
						
						</div>	
						
					</div>			
				
				</div>	
			</div>	
			
			<br />
			<br />			
			
			<Footer />				
		
			</AppContext.Provider>
		</>
			
	);
}

export default App;
