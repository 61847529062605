
let service1_chi = `會員系統
購物車
網上付款 : Visa / Master / Paypal / Alipay
訂單電郵通知
SMS通知
SEO優化設置
Facebook/Instagram SEO優化 / 廣告宣傳`;

let service1_eng = `Membership
Shopping Cart
OnlinePayment : Visa / Master / Paypal / Alipay
Order Email Notification
SMS Notification
SEO
Facebook/Instagram SEO / Online Ads`;


let service2_chi =  `網站寄存
域名代購
IP租用
網路安全性 / 網站維護
SSL 安全證書
網站地圖(sitemap)
資料匯出
Google Maps
Google Analytics 人流分析`;


let service2_eng =  `Web Hosting
Domain Purchasing
IP Rent
Network Security / Website Maint.
SSL Cert
Sitemap
Data Export
Google Maps
Google Analytics`;


let service3_chi = `QRCode with logo 生成
動畫網站效果
公司圖標設計
Favicon
電腦版 及 手機版切換顯示`;

let service3_eng = `QRCode with logo 
Website Animation 
Company Logo Design
Favicon
Responsive Design`;

export const lang = {
	
	m_service0:{chi:'我們的服務',eng:'Our Service'},	
	
	m_home:{chi:'首頁',eng:'Home'},
	m_service:{chi:'服務',eng:'Service'},
	m_server:{chi:'雲伺服器',eng:'Cloud Server'},
	m_serverhk:{chi:'香港雲伺服器',eng:'Hong Kong Cloud Server'},
	m_ip1:{chi:'IPv4租用',eng:'IPv4 Leasing'},
	m_contact:{chi:'聯絡我們',eng:'Contact Us'},		
	m_login:{chi:'登入',eng:'Login'},			
	
	m_onestop:{chi:'一站式服務',eng:'One-Stop Services'},
	
	
	m_onestop_1_title:{chi:'域名和寄存',eng:'DOMAIN & HOSTING.'},
	m_onestop_1_content:{chi:'網頁、電郵、 域名解釋 (DNS) 等服務，所有資料寄存於數據中心。',eng:'E-business development, such like blogs and forums set up, to tailor-made different web hosting service for customers’ needs.'},
	
	m_onestop_2_title:{chi:'網頁設計',eng:'WEB DESIGN'},
	m_onestop_2_content:{chi:'形象設計，網頁設計，視覺設計，展位設計和系統控制台設計。',eng:'To help to establish corporate image, we also tailor-made websites for corporation and individuals with one-stop service.'},
	
	m_onestop_3_title:{chi:'系統及程式開發',eng:'SYSTEM & PROGRAM DEVELOPMENT'},
	m_onestop_3_content:{chi:'透過設計師和程序員合作打造良好使用體驗的介面和系統功能。',eng:'To gratified customer, our specialist program develop team is creating qualified software system as always.'},
	
	m_onestop_4_title:{chi:'技術支援',eng:'I.T. SUPPORT'},
	m_onestop_4_content:{chi:'現場上門服務進行硬件檢查及即場提供專業意見；而遠程支援節省時間。',eng:'To help corporation get away from IT difficulties, we are dedicated to provide quality services with our experienced profile.'},
	
	m_feature_1_title:{chi:'網頁寄存&雲端伺服器',eng:'WEB HOSTING & CLOUD SERVER' },
	m_feature_1_content:{chi:'您可於何時、何地瀏覽網站或連接相關服務',eng:'You may browse web and connect to relevant service anytime, anywhere!' },
	
	m_feature_2_title:{chi:'IPv4及雲伺服器租用',eng:'IPv4 & CLOUD HOSTING' },
	m_feature_2_content:{chi:'IPv4及雲伺服器租用',eng:'IPv4 & Cloud Hosting' },      	

	m_feature_3_title:{chi:'網頁及郵箱寄存服務',eng:'WEBSITE & MAILBOX SERVICE' },
	m_feature_3_content:{chi:'網頁及郵箱寄存服務',eng:'Website & Mailbox Service' },      
	
	m_addr_title:{chi:'地址',eng:'Address' },
	m_addr_content:{chi:'香港九龍觀塘區勵業街9號 同利工業大廈12樓47號室',eng:'RM47, 12/F, Tung Lee Industrial Building, No. 9 Lai Yip Street, Kwun Tong' },
		
	m_opinion:{chi:'給我們一些意見',eng:'Write A Comment' },
	
	m_newproject_title:{chi:'開始一個新項目',eng:'START A NEW PROJECT?' },	
	m_newproject_content:{chi:'致力為各中小企業服務，歡迎聯絡我們！',eng:'With the plentiful experiences we have got, Winning Partner always committed to assist local ' },
	
	m_opinion_name:{eng:'Name',chi:'姓名' },	
	m_opinion_email:{eng:'Email',chi:'電郵' },	
	m_opinion_company:{eng:'Company Name',chi:'公司名' },	
	m_opinion_tel:{eng:'Whatsapp',chi:'Whatsapp' },	
	m_opinion_submit:{eng:'Submit',chi:'提交' },
	m_opinion_msg:{eng:'Opinion',chi:'意見' },
		
	m_service_1_title:{chi:'電子商務',eng:'E-Commercial' },	
	m_service_1_content:{chi:  service1_chi.split("\n").map(x=><div>{x}</div>)  ,eng:service1_eng.split("\n").map(x=><div>{x}</div>) },	

	m_service_2_title:{chi:'寄存服務',eng:'HOSTING SERVICE' },	
	m_service_2_content:{chi:  service2_chi.split("\n").map(x=><div>{x}</div>)  ,eng:service2_eng.split("\n").map(x=><div>{x}</div>) },	
	
	m_service_3_title:{chi:'設計服務',eng:'DESIGN SERVICE' },	
	m_service_3_content:{chi:  service3_chi.split("\n").map(x=><div>{x}</div>)  ,eng:service3_eng.split("\n").map(x=><div>{x}</div>) },	
	
	ip_a_title:{0:{chi:'龐大的 IPv4 地址池',
					eng:'Huge pool of IPv4 address'
					},
				1:{chi:'提供 IPv4 地址的全球地理位置',
					eng:'Offer Global geo-location of IPv4 address'
					},
				2:{chi:'48小時內提供IPv4地址',
					eng:'Provide IPv4 address within 48 hours'
					},	
				3:{chi:'獲取IP的簡單快捷的過程',
					eng:'Simple and quick process to get the IPs'
					},	
				4:{chi:'/16 租賃的 4 倍分配',
					eng:'4 times allocation for /16 leasing'
					},	
				5:{chi:'無其他管理或維護費用',
					eng:'No other management or maintenance fee'
					},
	},
	ip_a_content:{0:{chi:'Winning Partner管理著手頭大量的 IPv4 地址，隨時可以使用',
					eng:'Winning Partner managed a large number of IPv4 addresses on hand and is ready to use anytime.'
					},
				1:{chi:'IPv4 地址來自不同的國家，我們已準備好為全球網絡提供服務。',
					eng:'The IPv4 addresses are coming from different countries and we are ready to serve the global network.'
					},
				2:{chi:'我們將確保您獲得 IPv4 地址並在 48 小時內開始使用它們',
					eng:'We will make sure you get the IPv4 addresses and start using them within 48 hours.'
					},	
				3:{chi:'只需3步即可獲取IP地址：簽署合約、支付、授權',
					eng:'3 simple steps to get the IPv4 address which are service contract, payment, and letter of authorization'
					},	
				4:{chi:'租賃/16，可根據您的需求分4次取用。',
					eng:'/16 leasing similar to receive 65,000 IPv4 addresses for serving multi-purpose needed.'
					},	
				5:{chi:'清晰及明確的收費，租賃期間不會收取額外費用',
					eng:'The cost is stated clearly and there will be no extra costs during the leasing periods.'
					},
	},
	
	
	ip_b_title:{0:{chi:'沒有複雜的 RIR 轉移程序',
					eng:'No complicated RIR transfer procedure'
					},
				1:{chi:'無需前期大量投資',
					eng:'No upfront heavy investment required'
					},
				2:{chi:'快速部署',
					eng:'Immediate deployment by IP specialist'
					},	
				3:{chi:'確保 IPv4 的未來供應',
					eng:'Secure the future supply on IPv4'
					},	
				4:{chi:'',
					eng:''
					},	
				5:{chi:'持續支持 24/7',
					eng:'Ongoing Support 24/7'
					},
	},
	
	ip_b_content:{0:{chi:'無需通過 RIR 會員資格，因為 IP 地址將從 Winning Partner 的池中分配給您。',
					eng:'No need to go through RIR membership because IP addresses will be assigned to you from Winning Partner pool.'
					},
				1:{chi:'節省購買IP地址的成本，租用IP地址只要您需要。',
					eng:'Save cost on buying IP addresses and lease IP address as long as you need.'
					},
				2:{chi:'IP 地址將在幾天內快速部署，因為您將跳過複雜的過程，例如 IP 代理、協議等。',
					eng:'IP addresses will be deployed quickly in a couple of days because you will be skipping the complicated process such as IP brokerage, agreements, and so on.'
					},	
				3:{chi:'由於IP地址的稀缺性，採購IP地址非常耗時，因此按需租用IP地址是最有效的方式。',
					eng:'Sourcing IP address is time-consuming due to their scarcity, so lease on-demand IP addresses is the most efficient way.'
					},	
				4:{chi:'',
					eng:''
					},	
				5:{chi:'我們技術精湛、經驗豐富的知識產權團隊將為您提供持續的管理服務和支持。',
					eng:'Our highly skillful and experienced IP team will provide ongoing management service and support to you.'
					},
	},
	
	
	ip_c_title:{0:{chi:'服務合約',
					eng:'1. Service Contract'
					},
				1:{chi:'付款',
					eng:'2. Payment'
					},
				2:{chi:'授權書',
					eng:'3. Letter Of Authorization'
					},	
				
	},
	
	ip_c_content:{0:{chi:'一旦確定了 IPv4 地址總數和租期，我們的 IP 專家將準備服務合約。',
					eng:'Once confirmed on the total numbers of IPv4 addresses and leasing period, our IP specialist will prepare the service contract.'
					},
				1:{chi:'簽訂服務合約後，您將收到我們的發票並進行付款。',
					eng:'After the service contract is signed, you will receive an invoice from us and proceed to make the payment.'
					},
				2:{chi:'收到付款後，我們的 IP 專家將準備授權書，您將從我們這裡獲得 IP 地址。',
					eng:'After payment is received, our IP specialist will prepare the Letter of Authorization and you will get the IP address from us.'
					},	
				
	},
	
	ip_a_title0:{chi:'IP租用服務',
				eng:'IP Leasing Service'},
				
	ip_b_title0:{chi:'為甚麽選我們',
				eng:'Why Choose Us'},
				
	ip_c_title0:{chi:'3步最得IPv4(48小時內)',
				eng:'3 Steps To Lease IPv4 Addresses Within 48 Hours'},

			

			
	m_server_1_title:{chi:'跨機房熱遷移服務',
					eng:'Cross-Data-Center Hot Migration Service'},
	m_server_1_content:{chi:'打破地域限制，輕鬆應對時間空間帶來的運營困擾，最快只需5分鐘，即可將數據從一機房遷移至另一機房。',
						eng:'Break through geographical limitations and easily cope with operational problems caused by time and space constraints. In just 5 minutes, data can be migrated from one data center to another.'},
						
	m_server_2_title:{chi:'伺服器線上 Push 功能',
						eng:'Server Online Push Function'},
	m_server_2_content:{chi:'Winning Partner鼓勵支持用戶將手中閒置伺服器隨時轉讓，提高閒置資源使用率，減少資源浪費並幫用戶有效降低成本。',
						eng:'Winning Partner encourages users to transfer their idle servers at any time, improve resource utilization, reduce waste, and effectively reduce costs.'},	

	m_server_3_title:{chi:'三層存儲技術',
						eng:'Three-Layer Storage Technology'},
	m_server_3_content:{chi:'緩存、沉澱、備份層，三層分別對數據進行處理、緩存與災備，三層間實時同步，數據安全性達到99.999%，高可用性達到99.99%。',
						eng:'The three layers of caching, sedimentation, and backup respectively process, cache, and disaster recovery data. The three layers are synchronized in real time, with data security reaching 99.999% and high availability reaching 99.99%.'},	
						
						
	m_server_4_title:{chi:'免費50G防禦',
						eng:'Free 50G Defense'},
						
	m_server_4_content:{chi:'Winning Partner採用自帶硬防節點，最高可享免費50G防禦，最高實現300G防禦峰值，有效防禦DDoS、CC等惡意攻擊，保障用戶網絡安全。',
						eng:'Winning Partner uses its own hard defense nodes and provides free 50G defense, with a maximum peak defense value of 300G. It effectively defends against malicious attacks such as DDoS and CC, ensuring users network security.'},	
						
	m_server_5_title:{chi:' 性能可靠，確保業務穩定 ',
						eng:'Reliable Performance Ensures Stable Business'},
						
	m_server_5_content:{chi:'Winning Partner服務器在硬件級別上實現雲主機之間的完全隔離；採用高端服務器進行部署，同時採用集中的管理與監控，確保業務穩定可靠。',
						eng:'Winning Partner servers achieve complete isolation between cloud hosts at the hardware level; using high-end servers for deployment, while utilizing centralized management and monitoring to ensure reliable and stable business operations.'},				
						
						
	
	m_server_6_title:{chi:'靈活部署，助您一鍵上雲',
					eng:'Flexible deployment, helping you move to the cloud with one click'},
	m_server_6_content:{chi:'針對不同行業、領域的企業級用戶，以專業的一站式行業雲解決方案，幫助用戶快速應用雲計算。',
						eng:'For enterprise-level users in different industries and fields, we provide a one-stop industry cloud solution with professional and mature expertise, helping users to quickly apply cloud computing.'},	
						
							
	m_server_7_title:{chi:'純SSD架構配備頂級硬件',
					eng:'Pure SSD architecture equipped with top-notch hardware'},
	m_server_7_content:{chi:'搭建純SSD架構的高性能企業級雲伺服器，同時採用高端Intel Haswell CPU、高頻DDR4內存、高速SAS3 SSD閃存作為底層硬件配置，打造快速訪問體驗。',
						eng:'We build high-performance enterprise-level cloud servers with pure SSD architecture, using high-end Intel Haswell CPU, high-frequency DDR4 memory, and high-speed SAS3 SSD flash memory as the underlying hardware configuration, to create a fast access experience.'},	
						
	m_server_8_title:{chi:'全網BGP帶寬',
					eng:'Full network BGP bandwidth'},
	m_server_8_content:{chi:'Winning Partner使用BGP線路，徹底解決南北互聯互通的問題。同時自身具有容災性，保障線路安全穩定讓用戶體驗到最佳的訪問體驗。',
						eng:'NetEase Cloud uses BGP routing to completely solve the problem of north-south interconnection. At the same time, it has its own disaster recovery capabilities, ensuring that the network is safe and stable, allowing users to experience the best possible access speed.'},

												
							
	m_server2_1_title:{chi:'適用對象：小型企業官網或個人站長',
						eng:'For Small Business Websites and Personal Website Owners'},
						
	m_server2_1_content:{chi:'網站初始階段並發訪問量小，只需要一台低配置的服務器即可，<br>應用程序、數據庫、文件等所有資源均在一台服務器上，後期可以基於雲計算彈性特徵隨時調整資源配置，<br>無需擔心低配服務器在業務突增時帶來的資源不足問題。<br>適用於個人網站初始階段並發訪問量小，經濟配置省錢適用。',
						eng:'For websites with low initial traffic, a low-end server can meet all needs, with applications, databases, and files hosted on the same server. Cloud computing provides flexibility to adjust resource allocation as traffic increases, without worrying about resource shortages from low-end servers. Ideal for personal websites or small business websites with low traffic and cost-efficient requirements.'},
											
						
	m_server2_2_title:{chi:'適用對象：地方與行業門戶網站',
						eng:'For Regional and Industry Web Portals'},
						
	m_server2_2_content:{chi:'社區網站業務較個人網站，有更多的用戶訪問，為保證性能，<br>此時就需要對帶寬、內存、CPU進行優化，以便提供更大的空間，提升訪問速度。<br>適合流量適中的網站應用，或簡單開發環境、代碼存儲庫等。',
						eng:'For community websites with more user traffic, optimizing bandwidth, memory, and CPU is necessary to ensure performance and provide more space and faster access speeds. Suitable for websites with moderate traffic and simple development environments or code repositories.'},
												
										
						
	m_server2_3_title:{chi:'適用對象：網上商城、團購網 ',
						eng:'Applicable to: Online shopping malls, group buying websites'},
	m_server2_3_content:{chi:'隨著電子商務的不斷發展，眾多企業逐漸意識到電子商務的重要性。<br>該配置適用於開發、測試、上線初期，後期可根據您的業務實際增長進行增減，可靈活控制。<br>計算能力滿足90%雲計算使用者需求，適合企業運營活動、並行計算應用、普通數據處理服務等。',
						eng:'With the continuous development of e-commerce, many enterprises gradually realize the importance of e-commerce. <br> This configuration is suitable for development, testing, and early stage of going online. It can be flexibly controlled according to the actual growth of your business in the later stage. <br> The computing power meets the needs of 90% of cloud computing users, suitable for enterprise operation activities, parallel computing applications, general data processing services, etc.'},
	m_server2_4_title:{chi:'適用對象：社區SNS/論壇/ERP/OACRM、網絡遊戲等其他高端服務 ',
						eng:'Applicable to: Community SNS/Forums/ERP/OACRM, and other high-end services such as online games'},
	m_server2_4_content:{chi:'適合對計算性能要求較高的應用場景，如企業運營活動、批量處理、<br>分布式分析、遊戲apps等。<br>網絡遊戲在近年來發展迅速，除了傳統網絡終端之外，<br>網頁遊戲、手機遊戲等發展迅速，也讓整個遊戲市場愈發壯大。Winning Partner按需付費的方式節省了大量現金流和運營費用。',
						eng:'Suitable for application scenarios with higher requirements for computing performance, such as enterprise operation activities, batch processing, distributed analysis, game apps, etc. <br> In recent years, online games have developed rapidly. In addition to traditional network terminals, web games, mobile games, etc. have also developed rapidly, making the entire game market more prosperous. NetEase Cloud saves a lot of cash flow and operating costs through pay-as-you-go payment.'},

												
	m_server3_1:{chi:'入門型',
				eng:'Starter',
				parameter:2
				},						
						
	m_server3_2:{chi:'進階型',
				eng:'Advance',
				parameter:4},	
	
	m_server3_3:{chi:'專業型',
				eng:'Professional',
				parameter:8},	
		
	m_server3_4:{chi:'卓越型',
				eng:'Excellence',
				parameter:16},	
				
				
	m_server_misc_1:{chi:'產品優勢',
					eng:'Advantage',},
					
	m_server_misc_2:{chi:'適用場景',
					eng:'Scene',},
					
	m_server_misc_3:{chi:'建議',
					eng:'Recommend ',},

	m_server_misc_4:{chi:'',
					eng:'',},					
			

	m_server_desc:{chi:'Winning Partner 配備純SSD架構打造的高性能存儲系統，旨在為用戶提供優質、高效、靈活彈性的雲計算服務。雲服務器採用由數據切片技術構建的三層存儲功能，確切保護客戶數據的安全。同時可彈性擴展的資源用量，為客戶業務在高峰期的順暢保駕護航；靈活多樣的計費方式，為客戶提供最大程度節省IT運營成本，提高資源的有效利用率。 ',
					eng:'Winning Partner cloud server is equipped with high-performance storage built with pure SSD architecture, aiming to provide users with high-quality, efficient, and elastically scalable cloud computing services. The cloud server adopts a three-tier storage function constructed by data slicing technology to effectively protect the security of customer data. At the same time, the elastically expandable resource usage guarantees the smooth operation of customer business during peak periods; flexible and diverse billing methods save customers IT operating costs to the greatest extent and improve the effective utilization of resources.'
					},
					
	aboutus:{chi:`勝利拍檔軟件開發有限公司成立於2022年6月，是一家年輕而充滿活力、勇於創新的軟件開發公司。我們的宗旨是『你講得出、我做得到』，致力於為客戶提供高品質的服務。

				作為一家全方位的服務供應商，除了軟件開發，我們還提供一系列的專業服務。這些服務包括租用伺服器、網頁設計、網站寄存、電子商務解決方案、會員系統開發、域名代購、IP租用、網路安全性優化、網站維護以及網絡工程等。我們的目標是為客戶提供全面且有競爭力的解決方案，以滿足他們的需求。

				如果您有任何除以上提到的服務之外的需求，請隨時通過電話、電郵或短訊與我們聯繫。我們團隊擁有豐富的經驗和專業知識，很可能能夠為您提供出乎意料的解決方案。

				我們以客戶滿意度為首要目標，注重品質、創新和溝通。我們相信持續與客戶合作和建立夥伴關係，能夠共同實現長期的成功。
				`,
			eng:`Winning Partner Software Development Limited was founded in June 2022. We are a young, dynamic, and innovative software development company. Our motto is "You say, we deliver" and we are committed to providing high-quality services to our clients.

					As a comprehensive service provider, we offer a range of professional services in addition to software development. These services include server rental, web design, website hosting, e-commerce solutions, membership system development, domain name acquisition, IP leasing, network security optimization, website maintenance, and network engineering. Our goal is to provide our clients with comprehensive and competitive solutions to meet their needs.

					If you have any requirements beyond the services mentioned above, please feel free to contact us via phone, email, or text message. Our team has extensive experience and expertise, and we are likely to provide you with unexpected solutions.

					Customer satisfaction is our top priority, and we value quality, innovation, and communication. We believe that continuous collaboration and building partnerships with our clients can lead to long-term success.
					`
	},
	
	aboutus_title:{
		chi:`公司簡介`,
		eng:`About Us`
		
	},
									
			
}




