//import logo from './finalbee-01.png';
import './App.css';
import {useState,useEffect,useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaGreaterThan,FaLessThan } from "react-icons/fa";
import {BsFillHouseDoorFill,BsChevronBarExpand } from "react-icons/bs";
import BackendBanner from './BackendBanner';
import alipay from './img/alipay-png.png';

import Welcome from './Welcome';
import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import ReactSlider from "react-slider";
import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { onelinefree,URL0,blue,linefee } from './config';
import Info from './Info';

import hardwareconfig from './hardware.json';
const disclaimer=`本店條款、送貨及退貨安排 TERMS CONDITION AND DELIVERY RETURN POLICY  

本店條款

Winning Partner Software Development Ltd. (下稱本店 / 本網店) 將依照個人資料保護法相關規定保護您的個人資料，並遵守本店的私隱保護政策。
如有任何爭議，本店將保留最終決定權而毋須另行通知。
商品顏色或會因電腦螢幕設定會略有不同，一切以實物為準。
所有產品保養只限於香港地區。

送貨、退貨及退款

本店貨品由本店委託的速遞公司負責，詳情可參閱送貨服務。
客人下單後會收到電郵 / SMS 通知相關送貨詳情。如客人於一星期內未能收到電郵 / SMS 通知或欲跟進運單狀況，歡迎電郵至 sales@wpsdhk.com 與我們聯絡。
本店出貨時間：星期一至五(不包括公眾假期)。
本店接受退貨，詳情請參閱下列安排。
凡涉及退款事宜，一般情況下，使用信用卡付款的客人將會於七個工作天內 (不包括星期六、日及公眾假期) 於原有信用卡戶口中收到相關退款；使用銀行 ATM 轉帳的則一律以支票入數的形式作退款，客人亦會於三十個工作天內 (不包括星期六、日及公眾假期) 於原有銀行戶口中收到相關退款。如有任何查詢，歡迎電郵至 sales@wpsdhk.com 與我們聯絡。
如有任何爭議，Winning Partner Software Development Ltd. 保留條款的最終解釋權和決定權。
 

本商店退貨安排

需於收到貨品後十四天內電郵至 sales@wpsdhk.com 提出退換貨品申請。
需於客戶服務員發出的確認電郵發出後起計七個工作天內 (不包括星期六、日及公眾假期)，將貨品連同收據正本及原始包裝用料一起自行退回本公司，地址如下：
 
辦事處

RM47 12/F, Tung Lee Industrial Building,
No.9 Lai Yip Street, Kwun Tong,
Kowloon, Hong Kong

辦公時間：星期一至五早上九時至下午六時 (不包括公眾假期)

不適用於自然損耗。即正常使用下造成的磨損及破裂、不正確使用及人為疏忽引致的損壞、經自行修復和改造、意外損壞等。
已使用或因個人原因而造成任何損壞的商品均不能退換。
請保留及退回所有包裝，包括所有包裝箱、貨品原裝包裝盒、商品原標籤、吊牌、證明文件、廠商保證書、說明書、贈品及全部配件等，並保證所有包裝在完好無缺的狀態下退回。
在未能符合退貨規範的情況下，本店保留權利，拒絕接納任何退貨或換貨。
商品顏色或會因電腦螢幕設定會略有不同，一切以實物為準。
一張訂單只可進行一次退貨。
如有任何查詢，歡迎電郵至 sales@wpsdhk.com 與本店聯絡。

本條款內容若有修改，恕不另行通知

`;


var tabsContent = {margin:"0px",border:"1px solid #E0E0E0",borderTop:"0px solid black"};

let infomap = {				
				
				"agree":"Please tick the agreement of the T&C",
				"network":"Total bandwidth should be between 1MB to 1000MB",								
			};
			
			
var price = {
	'ThinkSystem ST250':2279.25, //https://www.lenovo.com/us/en/p/servers-storage/servers/towers/77xx7trst25/7y45a06dna
	'ThinkSystem ST250 V2': 2973.00,  //https://www.lenovo.com/us/en/p/servers-storage/servers/towers/thinksystem-st250-v2/len21ts0005?orgRef=https%253A%252F%252Fwww.google.com%252F&sortBy=savingAmount	
	'ThinkSystem ST50 V2' :1405.00, 	//https://www.lenovo.com/us/en/p/servers-storage/servers/towers/thinksystem-st50-v2/len21ts0004?sortBy=savingAmount
	'ThinkSystem ST50': 1331.00 ,// https://www.lenovo.com/us/en/p/servers-storage/servers/towers/thinksystem-st50/77xx7trst51?sortBy=savingAmount
	'ThinkSystem ST650 V2':2984.40 // https://www.lenovo.com/us/en/p/servers-storage/servers/towers/thinksystem-st650-v2/len21ts0001?sortBy=savingAmount	
}
			
function App() {
		
	const ipqtyRef = useRef();
	const periodRef = useRef();
	const agreeRef = useRef();
	
	const paymentMethod = useRef();
	
	const [CPU,setCPU] = useState(4);
	const [RAM,setRAM] = useState(4);
	const [HD,setHD] = useState(200);
	const [local,setLocal] = useState(10);
	const [china,setChina] = useState(0);
	const [intl,setIntl] = useState(0);
	const [amount,setAmount] = useState(0);
	const [os,setOS] = useState('centos');
 		
	const [show1, setShow1] = useState(false);
	const [infos,setInfos] = useState([]);
	
	const [hwconfig,setHwconfig] = useState( '' );
	
	useEffect(()=>{		
		//setIpqty(ipqtyRef.current.value);
		//setPeriodLabel(periodRef.current.options[periodRef.current.selectedIndex].text);
		//setPeriodValue(periodRef.current.value);				
	},[]);
		
	
	useEffect( ()=>{		
		setAmount( onelinefree + china*200 + (intl*20) + (local*1) + HD*1 + (RAM*100) + (CPU*100) );
	},[china,local,intl,HD,RAM,CPU]);
			
	const checkValid = () => {
		let tmp = [];	
		if (!agreeRef.current.checked){
			tmp.push('agree');
		}		
				
		setInfos(tmp);			
		if (tmp.length==0){			
			submit();
		}				
	}
	
	const totalBandwidth = () => {
		return china + local + intl;
	}
	
	const btnstyle = (choose) => {
			return {margin:"4px 4px 4px 4px",
					padding:"10px 10px 10px 10px",
					border:"1px solid black",
					cursor:'pointer',					
					backgroundColor:choose==os?blue:'white',
					color:choose==os?'white':'black'
			}
	};

	const showInfo = (x) => {
		return infos.includes(x) && <Info infomap={infomap} x={x} />		
	}

	const submit = (fn) => {
		let data = {
				cpu:CPU,
				ram:RAM,
				hd:HD,
				os:os,
				local:local,
				china:china,
				intl:intl,
				amount:amount
		};
		
		fetch(URL0 + '/post.php?action=buymachine', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {			
			},
		})
		.then((res) => res.text())
		.then((data) => {
			console.log(data);
			data = JSON.parse(data);
			if (data['result']){				
				setShow1(true);
			}			
		})
		.catch((err) => console.error(err));
	}
	

	return (<>		
			<Modal show={show1} onHide={()=>setShow1(false)}>
				<Modal.Header closeButton>
				<Modal.Title>Thank You</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				Order success. We will proceed to you later.
				</Modal.Body>
				 <Modal.Footer>
				<Button variant="secondary" onClick={()=>setShow1(false)}>
				Close
				</Button>
				
				</Modal.Footer>
			</Modal>
	
			<div style={{
				marginTop:"10px",
				paddingTop:"20px",
				backgroundColor:"#FFFFFF",
				border:"0px solid black",		
				filter: "drop-shadow(1px 1px 5px #808080)",
				}} className="container-lg">
				
				<div style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
					{/* <div><input type='button' style={{border:"1px solid " + blue,color:blue,fontWeight:'bold'}} value=' Login ' /> </div>*/}				
				</div>
				
				<BackendBanner />		
				
				<hr />
				
				<Welcome />
				
				<div style={{fontWeight:'bold',fontSize:"20px",marginBottom:"20px"}}>Hardware </div>
				
				<div className="mm1" style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"space-between"}}>
				
				<div style={{display:'flex',flexDirection:'column'}}>
				{hardwareconfig.map(item => {
					return <div key={item['name']} style={{display:'flex',flexDirection:'row'}}>
								
								<img src={URL0+'/image/'+item['name']+'.webp'} style={{width:"100px",height:"100px"}} />
								<div style={{width:"30%", whiteSpace:'nowrap'}} > {item['name']}</div>
								<div style={{width:"70%",display:'flex',marginBottom:"20",flexDirection:'column'}}>
									{item['spec'].map(spec=> {
										if ( !!spec['ATTRIBUTE']==false ){
											spec['ATTRIBUTE'] = spec['DESCRIPTION'];
										}
										if ( !!spec['SPECIFICATION']==false ){
											spec['SPECIFICATION'] = spec['SPECIFICATIONS'];
										}
										if (['Processors','Number of Processors','Processor','Memory','Expansion Slots'].indexOf(spec['ATTRIBUTE'])==-1) return null;
										return <div key={spec['ATTRIBUTE']} style={{display:'flex',flexDirection:'column'}}>
											<span style={{color:'red'}}> {spec['ATTRIBUTE'].replace('Number of Processors','Processor')}</span>
											{spec['SPECIFICATION']}
										</div>
									})}
									<br />
									<div style={{fontWeight:"bold"}}> HKD $ { Math.round(price[item['name']] * 7.9  /100) *100 } </div>
									<div onClick={ ()=> setHwconfig(hwconfig==item['name']?'':item['name']) }  style={{cursor:'pointer',textAlign:'center',width:"100px",backgroundColor:hwconfig==item['name']?blue:'gray',color:'white',border:"1px solid black"}}> Choose</div>
									
									<br /><br />
								</div>
						</div>
				})}
				</div>
						
				</div>		
				<br />	

				<br />	<br />	
				
				
				
				<div style={{marginLeft:"10%",width:"80%"}}>
					<textarea defaultValue={disclaimer} rows={8} style={{'border':'1px solid black','fontSize':'14px',width:'100%'}}>											
					</textarea>
					
					<div >
						<span style={{fontSize:'15px'}}>
						<input ref={agreeRef} type='checkbox' style={{marginRight:"4px"}} />
						<span className='noselect' style={{cursor:"pointer"}} onClick={()=> agreeRef.current.checked=!agreeRef.current.checked} > I have read and agreed to the <a target="_blank" 
								href="https://wpsdhk.com/tandc.pdf" 
								style={{color:'blue'}} > General Terms and Conditions </a>
						</span>
						</span>
					</div>
					
					<br />
					Please Choose Payment Method &nbsp;&nbsp;&nbsp;&nbsp; <input type='radio' ref={paymentMethod} /> <img src={alipay}  style={{width:"80px"}} />
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<span onClick={()=> { 
										if (paymentMethod.current.checked){
											//pass
										} else {
											alert('Please Choose Payment Method');
											return;
										}
										
										if (agreeRef.current.checked){
											//pass 
										} else {
											alert('Please tick the agreement of the T&C'); 
											return;
										}
										if (hwconfig!='') {
											setShow1(true);
										} else {
											alert('Please Choose the product first') }  
										} 
									}
									style={{cursor:'pointer',
											textAlign:'center',
											padding:"5px",
											width:"100px",
											backgroundColor:blue,
											color:'white',
											border:"1px solid black"}}
								> Checkout </span>
				
				</div>
				
				
				
				<br /><br /><br />
				
				
				
			</div>
			<hr />
			<br />
			

			
		</>
			
	);
}

export default App;
