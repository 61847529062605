//import logo from './finalbee-01.png';
import './App.css';
import {useState,useEffect,useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AiFillEdit,AiFillMinusCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import BackendBanner from './BackendBanner';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {AiFillInfoCircle} from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import { URL0,blue} from './config';
import Info from './Info';

let infomap = {
				"password":"The password consists of 8-20 English letters, including uppercase ,lowercase, numbers and special symbols (!@#$%^&*().,-_)",
				"password2":"The passwords must be the same",
				"asnumber":"Please enter the AS number",
				"phone":"Please enter the phone number ",
				"agree":"Please tick the agreement of the T&C",
				"name":"Please enter your name",
				"company":"Please enter the company name ",
				"email":"Please enter the valid email address",
				"email2":"Please enter the valid email address",
				"br":"Please enter the BR ",
				"website":"Please enter the website",
				"addr":"Please enter the office address"				
			};

function App() {
	
	const navigate = useNavigate();
	const passwordRef = useRef();
	const password2Ref = useRef();	
	
	const agreeRef = useRef();
	const phoneRef = useRef();	
	const asnumberRef = useRef();
	const addrRef = useRef();	
	const websiteRef = useRef();	
	const nameRef = useRef();
	const emailRef = useRef();
	const email2Ref = useRef();
	
	const companyRef = useRef();		
	const asRef = useRef();
	const [file, setFile] = useState();
	const [infos,setInfos] = useState([]);
	const [msg,setMsg] = useState('');
	
	
	const [show1, setShow1] = useState(false);
	
	useEffect(()=>{		
		
		
	},[]);
	
	const checkValid = () => {
		let tmp = [];		
		let pwd = passwordRef.current.value.trim();
		let pwd2 = password2Ref.current.value.trim();
		if (pwd.length<8 && pwd.length>20){
			tmp.push('password');
		}
		if (!(/[\!\@\#\$\%\^\&\*\(\)\.\,\-\_]/.test(pwd) && /[0-9]/.test(pwd) && /[A-Z]/.test(pwd) && /[a-z]/.test(pwd) )){
			tmp.push('password');
		}
		if (pwd!=pwd2){
			tmp.push('password2');
		}
		
		if (addrRef.current.value==''){
			tmp.push('addr');
		}
		
		if (websiteRef.current.value==''){
			tmp.push('website');
		}
		
		//if (asnumberRef.current.value==''){
			//tmp.push('asnumber');
		//}
		if (phoneRef.current.value==''){
			tmp.push('phone');
		}	
		if (!agreeRef.current.checked){
			tmp.push('agree');
		}		
		
		if (nameRef.current.value==''){
			tmp.push('name');
		}		
		if (companyRef.current.value==''){
			tmp.push('company');
		}		
		if (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(emailRef.current.value)) {
			tmp.push('email');
		}		
		if (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email2Ref.current.value)) {
			tmp.push('email2');
		}	
		
		if (!file) {
			tmp.push('br');
		}
		
		setInfos(tmp);	
		console.log(tmp);
		if (tmp.length==0){
			//pass
			submit();
		}		
	}
			
	const showInfo = (x) => {
		return infos.includes(x) && <Info infomap={infomap} x={x} />		
	}
	
	const optionamount = () => {
		let num = [];
		for (let i=1;i<=256;i++){
			num.push(i);
		}		
		return <>{num.map(i=><option value={i}> {i} / 24 ( {256*i} )</option>)}</>		
	}
	
	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
		  setFile(e.target.files[0]);
		}
	};
	
	const submitdata = (fn) => {
		let data = {				
				phone:phoneRef.current.value,
				asnumber:asnumberRef.current.value,
				addr:addrRef.current.value,
				website:websiteRef.current.value,
				name:nameRef.current.value,
				email:emailRef.current.value,
				email2:email2Ref.current.value,
				company:companyRef.current.value,
				pwd:passwordRef.current.value,
				fn:fn
		};

		fetch(URL0 + '/post.php?action=reg', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {			
			},
		})
		.then((res) => res.text())
		.then((data) => {						
			console.log( data );
			data = JSON.parse(data);
			if (data['result']){
				setMsg(data['msg']);
				setShow1(true);
			}
		})
		.catch((err) => console.log(err));
	}
	
	const submit = () => {
		if (!file) {
			return;
		}			
		fetch(URL0 + '/post.php?action=file&type=' + file.type.split('/')[1], {
			method: 'POST',
			body: file,
			headers: {
			//'content-type': file.type,
			//'content-length': `${file.size}`, // 👈 Headers need to be a string
			},
		})
		.then((res) => res.text())
		.then((data) => {	
			
			data = JSON.parse(data);
			if (data['result']){
				submitdata(data['fn']);
			}
		})
		.catch((err) => console.log('11111' , err));
	};

	const termoption = () => {
		let terms = [{label:"1 month",month:1},
					{label:"3 months",month:3},
					{label:"6 months",month:6},
					{label:"1 year",month:12},
					{label:"2 years",month:24},
					{label:"3 years",month:36},
					{label:"4 years",month:48},
					{label:"5 years",month:60},
					{label:"6 years",month:72},
					{label:"7 years",month:84},
					{label:"8 years",month:96}
				];
		return <>{terms.map(x=> <option value={x['month']}> {x['label']} </option>  )}</>
		
	}
	
		
	return (<>	
			
			<Modal show={show1} onHide={()=>setShow1(false)}>
				<Modal.Header closeButton>
				<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				{msg}
				</Modal.Body>
				 <Modal.Footer>
				<Button variant="secondary" onClick={()=>setShow1(false)}>
				Close
				</Button>
				
				</Modal.Footer>
			</Modal>
	
			<div style={{
				marginTop:"10px",
				paddingTop:"20px",
				backgroundColor:"#FFFFFF",
				border:"0px solid black",								
				
				filter: "drop-shadow(1px 1px 5px #808080)",
				}} className="container-lg">
				
				<div style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
					{/* <div><input type='button' style={{border:"1px solid " + blue,color:blue,fontWeight:'bold'}} value=' Login ' /> </div>*/}				
				</div>
				
				<BackendBanner />
				
				<div className="mm1" style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"space-between"}}>
				
					<div   style={{width:"100%",border:"0px solid black"}}>
					
						
						<hr style={{marginTop:"40px",marginBottom:"40px"}} />
												
						<div style={{border:"0px solid black",color:blue,fontSize:"20px"}} >User Registration </div>
						<br />
						
						
						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('name')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('name')}	
						</div>
						
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1" >Your Name</span>							
							<input ref={nameRef} type="text" className="mm5 form-control" placeholder="Your Name" 
							aria-describedby="basic-addon1" />
						</div>
						
						
						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('email')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('email')}	
						</div>
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1" >Email</span>
							<input ref={emailRef} type="text" className="mm5 form-control" placeholder="Email" 
							aria-describedby="basic-addon1" />
						</div>
						
						
						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('password')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('password')}	
						</div>
						
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1" >Password</span>
							<input ref={passwordRef} type="text" className="mm5 form-control" placeholder="Password" 
							aria-describedby="basic-addon1" />
						</div>
						
						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('password2')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('password2')}	
						</div>
						
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1" >Password Confirm</span>
							<input ref={password2Ref} type="text" className="mm5 form-control" placeholder="Password Again" 
							aria-describedby="basic-addon1" />
						</div>
												
						

						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('email2')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('email2')}	
						</div>
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1" >Billing Email</span>
							
							<input ref={email2Ref} type="text" className="mm5 form-control" placeholder=" Billing Email" 
							aria-describedby="basic-addon1" />
						</div>
						
						
						
						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('phone')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('phone')}	
						</div>
		
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1" >Phone #</span>
							<input ref={phoneRef} type="text" className="mm5 form-control" placeholder="Phone Number" 
							aria-describedby="basic-addon1" />
						</div>
						
						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('asnumber')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('asnumber')}	
						</div>
					
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1"> AS Number</span>
							<input ref={asnumberRef} type="text" className="mm5 form-control" placeholder="Please enter AS number" 
							aria-describedby="basic-addon1" />
						</div>	
						
						
						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('company')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('company')}	
						</div>
					
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1"> CompanyName</span>
							<input ref={companyRef} type="text" className="mm5 form-control" placeholder="Company name" 
							aria-describedby="basic-addon1" />
						</div>	
						
						
						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('addr')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('addr')}	
						</div>
						
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1"> Address</span>
							<input ref={addrRef} type="text" className="mm5 form-control" placeholder="Office Address" 
							aria-describedby="basic-addon1" />
						</div>	
						
						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('website')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('website')}	
						</div>
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1"> Website</span>
							<input ref={websiteRef} type="text" className="mm5 form-control" placeholder="Office Website" 
							aria-describedby="basic-addon1" />
						</div>							
						
						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('br')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('br')}	
						</div>
						<div className="input-group mb-3">
							<span className="input-group-text inputfield1"> BR Copy</span>
							<input type="file"  onChange={handleFileChange} className="mm5 form-control" placeholder="Please br number" 
							aria-describedby="basic-addon1" />
						</div>	
						
						<br /><br />
					
						
						<div className='desktop-div'>
							<div className="input-group mb-3">
								<span className="input-group-text inputfield1" ></span>							
								{showInfo('agree')}	
							</div>
						</div>
						<div className='mobile-div'>
							{showInfo('agree')}	
						</div>
						
						<div >
							<span style={{fontSize:'15px'}}>
								<input  ref={agreeRef} type='checkbox' style={{marginRight:"4px"}} />
								<span className='noselect' style={{cursor:"pointer"}} onClick={()=> { console.log(agreeRef.current.checked) ; agreeRef.current.checked=!agreeRef.current.checked } }> 
									I have read and agreed to the 
									<a target="_blank" href="https://wpsdhk.com/tandc.pdf" style={{color:'blue'}} > General Terms and Conditions  </a>
								</span>
							</span>
						</div>
						
						<div style={{marginTop:"20px",marginBottom:"20px"}}>
							<input  style={{border:"2px solid "+blue,backgroundColor:blue,color:'white',width:"100%"}} type='button' value='Submit' onClick={()=>checkValid()} />
						</div>
					</div>					
				</div>		
				<br />		
				<div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>							
					<div style={{marginTop:"20px",marginBottom:"20px"}}>
						<span style={{cursor:'pointer'}} onClick={()=> navigate("/index", { replace: true })  } >  Back to Home Page</span>										 
					</div>					
				</div>				
			</div>
			<hr />			
			<br />
		</>
			
	);
}

export default App;
